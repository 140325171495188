import ButtonStyle from "./buttonStyle";

interface IButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  title: string;
  isDisabled: boolean;
}

const useButton = (props: IButtonProps) => {
  const { isDisabled } = props;
  const { enabledStyle, disabledStyle, containerClass } = ButtonStyle();

  const className = `${isDisabled ? disabledStyle : enabledStyle}`;

  return {
    className,
    containerClass
  };
};

export default useButton;

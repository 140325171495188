import { useQuery, useMutation } from "@apollo/client";
import { FETCH_USER, UPDATE_USER_PROFILE } from "../../queries";
import { useCallback, useEffect, useRef, useState } from "react";
import { ProfileFields, userResponse, UseUserHook } from "./types";

const useUser = (userId: string): UseUserHook => {
  const {
    loading: userLoading,
    error: userError,
    data,
    refetch
  } = useQuery<userResponse>(FETCH_USER, {
    variables: {
      userId
    },
    fetchPolicy: "cache-first"
  });
  const userData = data?.user;

  const initialProfile = useRef<ProfileFields>({
    fullName: undefined,
    email: undefined,
    password: undefined,
    passwordConfirmation: undefined,
    description: undefined,
    instagramUsername: undefined,
    youtubeUsername: undefined,
    tiktokUsername: undefined,
    birthdate: undefined
  });

  const [updateProfile] = useMutation(UPDATE_USER_PROFILE);
  const [profileFields, setProfileFields] = useState<ProfileFields>(initialProfile.current);

  useEffect(() => {
    if (userData) {
      const currentProfile = {
        fullName: userData?.fullName,
        description: userData?.description,
        email: userData?.email,
        password: undefined,
        passwordConfirmation: undefined,
        instagramUsername: userData?.instagramUsername,
        youtubeUsername: userData?.youtubeUsername,
        tiktokUsername: userData?.tiktokUsername,
        birthdate: userData?.birthdate
      };
      initialProfile.current = currentProfile;
      setProfileFields(currentProfile);
    }
  }, [userData]);

  const updateUserProfile = useCallback(
    async (updateField: { [key: string]: string | undefined }, onSuccess: () => void, onError: () => void) => {
      const response = await updateProfile({
        variables: {
          input: {
            ...profileFields,
            ...updateField
          }
        }
      });
      if (response?.data?.updateProfile?.success) {
        refetch();
        onSuccess();
      } else {
        onError();
      }
    },
    []
  );

  return { userLoading, userError, userData, updateUserProfile };
};

export default useUser;

import React, { useCallback, useMemo, useState } from "react";
import { useAuth } from "../../../../store/contextAPI/authContext";
import { IValue } from "../../Onboarding/hooks/types";
import { isEmailValid } from "../../../../utils/common-function";

const useForgotPassword = () => {
  const { user, setEmailContext, handleResetPassword } = useAuth();
  const [email, setEmail] = useState<IValue>({ value: user.email, error: "" });

  const onChangeEmail = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const processedValue = value.replace(/\s+/g, "").toLowerCase();
      const emailError = processedValue.length === 0 ? "" : isEmailValid(processedValue) ? "" : "Please enter a valid email";
      setEmail({ value: processedValue, error: emailError });
    },
    [isEmailValid]
  );

  const isContinueDisable = useMemo((): boolean => !isEmailValid(email.value), [email, isEmailValid]);

  const onClickContinueHandler = useCallback(async () => {
    setEmailContext(email.value);
    handleResetPassword(email.value);
  }, [email.value, setEmailContext]);

  const onClickResendHandler = useCallback(() => {
    handleResetPassword(email.value);
  }, []);

  return {
    email,
    onChangeEmail,
    isContinueDisable,
    onClickContinueHandler,
    onClickResendHandler
  };
};

export default useForgotPassword;

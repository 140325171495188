import React from "react";
import { useLogoutModal } from "./useLogoutModal";
import { LogoutModalProps } from "./types";

const LogoutModal: React.FC<LogoutModalProps> = ({ isModalOpen, setIsModalOpen, onConfirm }) => {
  const { onClose, handleLogout } = useLogoutModal(onConfirm, setIsModalOpen);

  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center z-50 overflow-hidden backdrop-blur-sm">
      <div className="bg-[#F8F8F8]/90 rounded-[14px] w-270">
        <div className="p-4">
          <div className="text-17px font-sfprotext font-semibold text-center mb-0.5 text-soft-black leading-snug">Are you sure?</div>
          <div className="w-[238px] font-normal leading-[18px] text-13px font-sfprotext text-center text-black">
            Please confirm that you’d like <br />
            to log out of your account
          </div>
        </div>
        <div className="flex justify-between border-t-2 border-grey/70">
          <button
            onClick={onClose}
            className="text-17px flex-1 text-[#007AFF] font-sfprotext text-center leading-snug font-bold py-2 px-4 border-r-2 border-grey/70">
            Cancel
          </button>
          <button
            onClick={handleLogout}
            className="text-17px flex-1 text-[#007AFF] font-normal leading-snug text-center font-sfprotext py-2 px-4">
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;

import { gql } from '@apollo/client';

export const FETCH_CAMPAIGN_PROFILE = gql`
  query FetchCampaignProfile($campaignId: ID!) {
    campaign(id: $campaignId) {
      id
      title
      description
      location
      imageUrl
      videoUrl
      canSupport
      currentAmount
      goalAmount
      numSupporters
      totalCoinDrops
      endTime
      disclosuresUrl
      shareUrl
      studentOrgs
      slug
      callToActionUrl
      associatedReferral {
        id
        referrer {
          id
          fullName
          username
          profilePhoto
        }
      }
      cause {
        id
        name
        description
      }
      charity {
        id
        name
        imageUrl
        activeSince
        city
        country
        description
        fullAddress
        location
        numEmployees
        postalCode
        registeredName
        stateProvince
        streetAddress
        yearFounded
        supportedCauses {
          id
          name
        }
        website
        username
        volunteerUrl
        instagramUsername
        twitterUsername
        facebookUsername
        youtubeUsername
        tiktokUsername
        linkedinUrl
        charityColor
        instagramData
        charityImages {
          id
          imageUrl
          identifier
        }
      }
    }
  }
`;

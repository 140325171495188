import { STORE_UPDATE_ATTENDEES } from "../../actions";

// Initial object with data exists
const initialState: any = {
  attendees: []
};

// Optional: If no initial data exists, structure needs to be created.
// const initialState: IUserInit = {
//     users: [],
//     user: {}
// };

const attendees = (state = initialState, action: any) => {
  switch (action.type) {
    case STORE_UPDATE_ATTENDEES:
      return {
        ...state,
        attendees: [...state.attendees, ...action.payload]
      };
    default:
      return state;
  }
};

export default attendees;

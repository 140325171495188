import React, { useState, useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { fileSizeLimitInMB } from "../../../utils/constants";

const CaptureVideo: React.FC = () => {
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [file, setFile] = useState<Blob | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunks: Blob[] = [];
  const history = useHistory();
  const location = useLocation();
  const isCreateStashlink = location.state as boolean;
  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "user" }, // Use the front camera
        audio: true
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        // Wait for the video metadata to be loaded before playing
        videoRef.current.onloadedmetadata = () => {
          videoRef.current?.play();
        };
      }

      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        chunks.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunks, { type: "video/mp4" });
        setFile(blob);
      };
    } catch (error) {
      console.error("Error accessing the camera:", error);
    }
  };

  const startRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.start();
      setIsRecording(true);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleUpload = () => {
    if (!file) return;
    const blob = new Blob(chunks, { type: "video/mp4" });
    const formData = new FormData();
    formData.append("file", blob, "video.mp4");
    navigateFunction(file, null);
  };
  const navigateFunction = (file: Blob | File | undefined | null, thankyouMessage: string | null) => {
    if (file) {
      const maxSizeInBytes = fileSizeLimitInMB * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        alert(`File size exceeds ${fileSizeLimitInMB} MB. Please select a smaller file.`);
        return;
      }
    }
    history.push("/create-stashlink", {
      file: file,
      thankyouMessage: thankyouMessage,
      isCreateStashlink: isCreateStashlink,
      from: location.pathname
    });
  };

  useEffect(() => {
    startCamera();
  }, []);

  return (
    <div
      className="fixed inset-x-0 top-0 bottom-0 z-50 flex flex-col items-center justify-between bg-black
">
      <div className="absolute top-4 left-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="lightgray"
          className="w-6 h-6 cursor-pointer absolute left-0 z-10"
          onClick={() => history.goBack()}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
        </svg>
      </div>

      <video ref={videoRef} className="w-full h-full object-cover" controls />

      <div className="absolute bottom-10 flex space-x-4">
        <button
          onClick={isRecording ? stopRecording : startRecording}
          className={`${isRecording ? "bg-red-500" : "bg-blue-500"} text-white px-6 py-3 rounded-full text-lg`}>
          {isRecording ? "Stop Recording" : "Start Recording"}
        </button>

        {file && (
          <button onClick={handleUpload} className="bg-green-500 text-white px-6 py-3 rounded-full text-lg">
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default CaptureVideo;

import Button from "../button";
import { IModal } from "./types";
import Close from "./../../../assets/images/cross.svg";

const Modal = (props: IModal) => {
  const { isOpen, onButtonClick, children, title, onClickClose, buttonTitle, isDisabled = false } = props;
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 px-4 backdrop-filter-blur">
      <div className="bg-gradient-to-t from-modal-bottom-bg to-modal-top-bg border-2 border-modal-top-bg rounded-3xl shadow-lg w-96">
        <div className="relative flex justify-end items-center p-4 border-b border-base-white/20">
          <h2 className="w-full text-center absolute left-2/4 -translate-x-2/4 text-base font-medium font-primary text-white">{title}</h2>
          <button onClick={onClickClose} className="text-white font-bold z-10">
            <img src={Close} alt={Close} />
          </button>
        </div>
        <div className="mt-4 p-4 pb-0">{children}</div>
        <div className="flex justify-end p-4">
          <Button onClick={onButtonClick} title={buttonTitle} isDisabled={isDisabled} />
        </div>
      </div>
    </div>
  );
};

export default Modal;

import { useState, useEffect } from "react";

const useCurrentUserStashLink = (currentUserId?: string, celebrityId?: string) => {
  const [isCurrentUserStashLink, setIsCurrentUserStashLink] = useState<boolean>(false);

  useEffect(() => {
    if (currentUserId && celebrityId) {
      if (currentUserId === celebrityId) {
        setIsCurrentUserStashLink(true);
      } else {
        setIsCurrentUserStashLink(false);
      }
    }
  }, [currentUserId, celebrityId]);

  return isCurrentUserStashLink;
};

export default useCurrentUserStashLink;

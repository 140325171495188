import * as React from "react";
import { useLocation } from "react-router";

const MainContent: React.FC<IProps> = ({ children }) => {
  const location = useLocation();

  React.useEffect(() => {
    const body = document.querySelector("#root");

    body?.scrollIntoView({
      behavior: "smooth"
    });
  }, [location]);

  return <div>{children}</div>;
};

export interface IProps {
  children: React.ReactNode;
}

export default MainContent;

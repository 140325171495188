import { gql } from '@apollo/client';

export const VIEW_STASHLINK = gql`
  mutation viewStashlink(
    $stashlinkId: String
    $source: String
  ) {
    viewStashlink(
      input: { stashlinkId: $stashlinkId, source: $source }
    ) {
      success
      error
      stashlink {
        thankYouMessage
        thankYouMessageVideo
        user {
          fullName
          profilePhoto
          haloColor
        }
      }
    }
  }
`;

import React from "react";
import Button from "../../components/common/button";
import Vs from "./../../assets/images/VS.svg";
import Divider from "./../../assets/images/head-to-head-divider.svg";
import HeadToHeadImageCard from "../../components/headToHeadImageCard/headToHeadImageCard";
import CountDownTimer from "../../components/common/countDownTimer/countDownTimer";
import { useHistory } from "react-router";
import { h2hTimerAlertInHours } from "../../utils/constants";
import { HeadToHeadPlayCardProps } from "./headToHeadPlayCard.types";

const HeadToHeadPlayCard: React.FC<HeadToHeadPlayCardProps> = (props: HeadToHeadPlayCardProps) => {
  const history = useHistory();

  return (
    <>
      <CountDownTimer endTime={props.endTime} alertTimer={h2hTimerAlertInHours} />

      <div className=" flex flex-col items-center justify-between w-full max-w-md rounded-lg ">
        <HeadToHeadImageCard player1Stashlink={props.player1Stashlink} player2Stashlink={props.player2Stashlink} />

        <div className="relative w-full grid grid-cols-2">
          <div className="relative text-right p-8 pb-20">
            <h3 className="flex items-center font-primary text-xl text-base-white mb-2 min-h-16">
              {props.player1Stashlink?.user.fullName}
            </h3>
            <p className="text-primary-l1 text-2xl mb-2 font-bold font-secondary">${props.player1Stashlink?.campaign.currentAmount}</p>
            <p className="text-sm text-grey font-secondary mb-2">raised for</p>
            <p className="text-base text-base-white font-secondary mb-4">{props.player1Stashlink?.campaign.title}</p>
            <div className="absolute w-3/4 bottom-0">
              <Button title="Play" isDisabled={false} onClick={() => history.push(`/campaign/${props.player1Stashlink?.campaign.id}`)} />
            </div>
          </div>

          <div className="relative text-left p-8 pb-20">
            <h3 className="flex items-center font-primary text-xl text-base-white mb-2 min-h-16">
              {props.player2Stashlink?.user.fullName}
            </h3>
            <p className="text-primary-l1 text-2xl mb-2 font-bold font-secondary">${props.player2Stashlink?.campaign.currentAmount}</p>
            <p className="text-sm text-grey font-secondary mb-2">raised for</p>
            <p className="text-base text-base-white font-secondary mb-4">{props.player2Stashlink?.campaign.title}</p>
            <div className="absolute w-3/4 bottom-0">
              <Button
                title="Play"
                isDisabled={false}
                onClick={() =>
                  history.push("/campaign/4b8fa503-b070-4251-a678-b94660e283c8/plant-trees-to-reforest-and-replenish-our-landscape")
                }
              />
            </div>
          </div>

          <img src={Vs} alt={Vs} className="absolute left-2/4 -translate-x-2/4 top-14" />
          <img src={Divider} alt={Divider} className="absolute left-2/4 -translate-x-2/4 top-24" />
        </div>
      </div>
    </>
  );
};

export default HeadToHeadPlayCard;

import React from "react";
import MarqueeComponent from "../../../components/common/marquee/marqueeComponent";
import GameBackground from "../../../assets/images/game-star.png";
import "../../../assets/css/index.css";
import useGameBubblePop from "../hooks/useGameBubblePop";
import { emptyBubble } from "../../../common/constants";
import InstructionalModal from "./instructionModal";
import ImageContainer from "../../../components/common/image-conatiner/image-container";
import { generateCloudinaryImageUrl, getLocalStorage } from "../../../utils/common-function";
import mixpanel from "../../../services/mixpanelService";
import WaitlistModal from "../../../components/waitlistModal";
import { formatTimeInSeconds } from "../../../utils/format";
import clsx from "clsx";
import { Stashlink } from "../../../types";

const BubblePopGame: React.FC = () => {
  const { bubbles, total, isGameComplete, focusCustomBackground, isModalOpen, revealBubble, time, closeModal, setIsModalOpen, isServerError, closeWaitListModal } =
    useGameBubblePop();
  const brandsData = getLocalStorage("brandsData");
  const stashlink = getLocalStorage("stashlink") as Stashlink;

  if (isServerError) {
    return <WaitlistModal isOpen={isServerError} onClose={closeWaitListModal} />;
  }

  const hasCustomBackground = !!stashlink?.promoBrandingImageUrl;
  const shouldHideTimer = isGameComplete || focusCustomBackground;

  return (
    <div className="flex flex-col items-center bg-soft-black text-white select-none">
      <div className="w-screen bg-black relative xxsh:hidden z-30">
        {!isGameComplete && (
          <MarqueeComponent text="Fast Fast Fast &nbsp; 👇 &nbsp; Tap Tap Tap" iterationsPerScreen={4} separatorIcon="👇" />
        )}
      </div>
        {hasCustomBackground ? (
          <div className="absolute h-screen inset-0 flex items-center justify-center z-10">
            <div className={clsx([
              "transition-all duration-[500ms] absolute size-full bg-black z-10",
              focusCustomBackground ? "opacity-0" : "opacity-65"
            ])} />
            <img
              src={generateCloudinaryImageUrl(stashlink?.promoBrandingImageUrl || "", "585", "1266")} alt=""
              className={clsx([
                "w-full h-screen object-cover disable-interaction transition-all duration-500",
                isGameComplete ? "scale-0" : "scale-100"
              ])}
            />
          </div>
        ) : null}
      <div className="w-full h-c-78 xxsh:h--7c8 flex flex-col items-center justify-between relative overflow-hidden">
        <div className="flex items-center justify-between px-4 w-full relative z-20 mt-3">
          <div
            className={`${total === 0 ? "text-grey" : "text-base-white"} lining-nums tabular-nums base-white px-4 py-2 bg-grey-bg rounded-full backdrop-blur-2 min-w-28 flex items-center justify-center font-semibold text-base
             leading-tight ${shouldHideTimer ? "hide-timer" : ""}`}>
            {formatTimeInSeconds(time) + "s"}
          </div>
          {/* <div
            className={`${total === 0 ? "bg-grey/20" : "bg-[#d7ff3e]"} ${isGameComplete ? "hide-amount" : ""} px-4 py-3  min-w-52 font-primary rounded-4xl flex flex-col items-center justify-center text-32px  font-extrabold capitalize lining-nums proportional-nums`}>
            <div className={`leading-tight ${total === 0 ? "text-grey" : "text-black"}`}>
              ${total === 0 ? total : total.toFixed(2)}
            </div>
          </div> */}
          <div
            className={`${total === 0 ? "bg-grey-bg" : "bg-[#d7ff3e]"} ${shouldHideTimer ? "hide-amount" : ""} h-9  min-w-32 font-primary rounded-4xl flex text-19 flex-col items-center justify-center font-extrabold capitalize lining-nums proportional-nums`}>
            <div className={`leading-tight ${total === 0 ? "text-grey" : "text-black"}`}>${total === 0 ? total : total.toFixed(2)}</div>
          </div>
        </div>
        {/* Added the timer for game completion */}
        <div
          className={`bg-primary-l1-dark px-3.5 absolute top-28 py-[6.98px] rounded-full border-2 border-black/40 z-20 backdrop-blur-sm flex-col justify-center items-start gap-[6.98px]
          inline-flex timer-animation timer ${isGameComplete ? "final-position" : "initial-position"} ${isGameComplete ? "timer-animation-tranformation" : ""}`}>
          <div
            className={`text-primary-l1 text-center tracking-wider leading-tight-29.28 text-2xl lining-nums tabular-nums font-semibold font-['Inter']`}>
            {formatTimeInSeconds(time) + "s!"}
          </div>
        </div>
        <div id="game" className={clsx([
          "transition-all duration-[500ms] w-full relative p-4 pt-0 flex flex-grow items-center justify-center",
        ])}>
          <div className={clsx([
            "absolute inset-0 -left-20 flex items-center justify-center z-10 bubble-game-background transition-all duration-500",
            hasCustomBackground && !isGameComplete && "hidden"
          ])}>
              <img src={GameBackground} alt="" className="w-full object-contain disable-interaction" />
          </div>
          <div
            className={`relative z-30 grid grid-cols-7 gap-1.5 mb-1 game-bubble-transform ${focusCustomBackground ? "game-bubble-resize" : ""}`}>
            {bubbles.map((bubble, index) =>
              emptyBubble.includes(index) ? (
                <div key={index} className="w-10 h-10 rounded-full"></div>
              ) : (
                <div
                  key={index}
                  onClick={() => revealBubble(index)}
                  onTouchStart={() => revealBubble(index)}
                  className={`coin ${bubble.revealed ? "flipping revealed-coin" : ""}`}>
                  {bubble.revealed && (
                    <div
                      className={`w-10 h-10 rounded-full flex items-center justify-center cursor-pointer ${
                        bubble.revealed ? "bg-black-op50" : "bg-primary-l1/40"
                      }`}></div>
                  )}
                </div>
              )
            )}
            {/* <div className="bg-[#1f1f23]/70 -bottom-8 rounded-lg backdrop-blur-sm flex-col w-full text-center absolute z-30 -mt-7 px-4">
              <div className="self-stretch justify-center font-secondary mt-5 text-sm text-white leading-tight-18.2 font-medium inline text-shadow-game">
                donated by
              </div>
              <div className="self-stretch font-primary text-center text-base font-wght-740 mt-2 leading-tight-19.2 text-neutral-50 inline text-shadow-game">
                {" "}
                {brandsData?.sampleEligibleBrands?.brand.name || ""}
              </div>
            </div> */}
          </div>

          <div className={clsx([
            "absolute inset-0 flex items-center justify-center pb-6 pointer-events-none z-30 transition-all duration-500",
            focusCustomBackground && !isGameComplete && "hidden"
          ])}>
            <div
              onClick={() => {
                setIsModalOpen(!isGameComplete);
                mixpanel.track("brand-logo-clicked");
              }}
              className={`w-[132px] h-[132px] bg-grey/30 rounded-[44px] flex items-center justify-center p-1 pointer-events-auto ${isGameComplete ? "transparency-logo-resize" : ""}`}>
              <ImageContainer
                src={generateCloudinaryImageUrl(brandsData?.sampleEligibleBrands?.brand.imageUrl || "", "243", "243")}
                className={`rounded-42px border-4 w-122 h-122 border-black sponsor-logo-transform bg-pure-black ${isGameComplete ? "sponsor-logo-resize" : ""}`}
              />
            </div>
          </div>
        </div>
        {/* Added the Dollar amount earn for game completioin */}
        <div
          className={`px-4 absolute bottom-20 py-3 bg-[#d7ff3e] rounded-[999px] justify-start z-20 items-center gap-[6.98px] inline-flex amountEarn ${
            isGameComplete ? "final-position" : "initial-position"
          } ${isGameComplete ? "amountEarn-transformation" : ""}`}>
          <div
            className={`text-center text-black text-32px font-extrabold font-['Syne'] flex justify-center items-center capitalize leading-[33.76px] ${
              isGameComplete ? "amountEarn-transformation" : ""
            }`}>
            $1.00
          </div>
        </div>
      </div>

      {/* <p className="font-secondary mt-5 text-base text-white leading-tight font-normal">Every cent you stash is donated by </p>
      <h1 className="font-primary capitalize text-center text-28 font-extrabold mt-2 leading-tight-29.54 text-neutral-50 mx-1">
        {brandsData?.sampleEligibleBrands?.brand.name || ""}
      </h1> */}
      <InstructionalModal brandName={brandsData?.sampleEligibleBrands?.brand.name || ""} isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default BubblePopGame;

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { contactEmail } from "../../utils/constants";
import { HamburgerMenuProps } from "./hamburgerMenu.types";
import Cross from "./../../assets/images/cross.svg";
import StashrunLogo from "./../../assets/images/Stashrun-beta-logo.svg";
import { routes } from "../../routes/routes.constant";
import useAuthStatus from "../../modules/campaign/hooks/useAuthStatus";
import mixpanel from "../../services/mixpanelService";
import { IoMdHeart } from "react-icons/io";
// import { getLocalStorage } from "../../utils/common-function";
// import FollowingCheck from "./../../assets/images/follow-check.svg";
// import { useFeatureIsOn } from "@growthbook/growthbook-react";

const HamburgerMenu: React.FC<HamburgerMenuProps> = (props: HamburgerMenuProps) => {
  const history = useHistory();
  // const isUserJoinedWaitlist: boolean = getLocalStorage("isUserJoinedWaitlist");
  const { isUserLoggedIn } = useAuthStatus();
  // const onboardingFeatureFlag = useFeatureIsOn("onboarding-flow");
  const navigateToCreate = () => {
    mixpanel.track("create-stashlink-clicked");
    if (isUserLoggedIn) {
      props.onClose();
      history.push({
        pathname: routes.callback,
        state: { currentPath: location.pathname, from: routes.colink, param: { isCreateStashLink: true } }
      });
    } else {
      history.push({
        pathname: routes.onboarding,
        state: { currentPath: location.pathname, from: routes.colink, param: { isCreateStashLink: true } }
      });
    }
  };

  useEffect(() => {
    if (props.isOpen) {
      mixpanel.track("hamburger-menu-opened");
      document.body.classList.add("prevent-scroll");
    } else {
      mixpanel.track("hamburger-menu-closed");
      document.body.classList.remove("prevent-scroll");
    }
    return () => {
      document.body.classList.remove("prevent-scroll");
    };
  }, [props.isOpen]);

  return (
    <div className="fixed inset-0 bg-soft-black z-50 flex flex-col items-start p-6 z-[100]">
      <button className="text-white text-2xl mb-6 relative -top-3 -left-2" onClick={() => props.onClose()}>
        <img src={Cross} alt={Cross} className="w-6 h-6 mb-4" />
      </button>
      <nav className="flex flex-col h-full mt-3 justify-between">
        <div className="flex flex-col space-y-6">
          <a
            onClick={navigateToCreate}
            className={`font-primary font-bold text-22px text-grey/70 pointer-events-none inline-block leading-normal tracking-tight`}>
            Create a Stashlink {<span className="text-sm font-secondary leading-tight-18.2 font-normal block -mt-1.5">Coming soon</span>}
          </a>
          {/* {!onboardingFeatureFlag ? (
            !isUserJoinedWaitlist ? (
              <a
                className="font-primary font-bold text-22px text-primary-l1 leading-normal tracking-tight"
                onClick={props.onClickJoinWaitlist}>
                Join the waitlist
              </a>
            ) : (
              <a className="font-primary font-bold text-22px text-grey/70 pointer-events-none inline-block leading-normal tracking-tight">
                On the waitlist
                <span className="ml-1 pt-1 w-6 h-6 inline-block">
                  <img src={FollowingCheck} alt="On waitlist Check" className="fill-current text-grey/70" />
                </span>
              </a>
            )
          ) : null} */}

          <a
            onClick={() => mixpanel.track("suggest-clicked", { location: "sidebar" })}
            href="https://www.stashrun.com/suggest"
            target="_blank"
            rel="noreferrer"
            className="font-primary font-bold text-22px text-primary-l1 leading-normal tracking-tight">
            Suggest
          </a>
          <a
            onClick={() =>
              mixpanel.track("contact-clicked", {
                location: "sidebar"
              })
            }
            href={`mailto:${contactEmail}`}
            className="font-primary font-bold text-22px text-primary-l1 leading-normal tracking-tight">
            Contact us
          </a>
          <a
            onClick={() =>
              mixpanel.track("about-clicked", {
                location: "sidebar"
              })
            }
            href="https://www.stashrun.com"
            target="_blank"
            className="font-primary font-bold text-22px text-primary-l1 leading-normal tracking-tight"
            rel="noreferrer">
            About
          </a>
        </div>

        <div>
          <img src={StashrunLogo} alt={StashrunLogo} className="h-[18px] w-auto" />
          <p className="opacity-80 text-neutral-50 text-sm font-normal font-secondary leading-[18.20px] mt-3">
            Play to fund charities together with <br></br>friends and your favorite celebs.
          </p>
          <p className="mt-4 opacity-80 text-[#b0c1d3] text-xs font-normal font-['Inter'] leading-none inter-I">© 2024 Stashrun Inc</p>
          <p className="mt-1 opacity-80 text-[#b0c1d3] text-xs font-normal font-['Inter'] leading-none">
            Made with <IoMdHeart className="text-sm inline relative -top-0.5" /> from California
          </p>
          <p className="mt-1 opacity-80 text-[#b0c1d3] text-xs font-normal font-['Inter'] leading-none underline-offset-4">
            <a
              onClick={() =>
                mixpanel.track("terms-clicked", {
                  location: "sidebar"
                })
              }
              target="_blank"
              href="https://www.stashrun.com/terms"
              className="underline"
              rel="noreferrer">
              Terms
            </a>
            {"  "}•{"  "}
            <a
              onClick={() =>
                mixpanel.track("privacy-clicked", {
                  location: "sidebar"
                })
              }
              target="_blank"
              href="https://www.stashrun.com/privacy"
              className="underline"
              rel="noreferrer">
              Privacy
            </a>
          </p>
        </div>
      </nav>
    </div>
  );
};

export default HamburgerMenu;

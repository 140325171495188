const VerifiedIcon = ({ color = "#D7FF3E", width = "12", height = "12", className = "" }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.88424 10.4368H2.23042C1.86113 10.4368 1.56183 10.1375 1.56183 9.76825V8.11376C1.56183 7.93645 1.49117 7.76647 1.36585 7.64115L0.195978 6.47128C-0.0653261 6.20998 -0.0653261 5.78669 0.195978 5.52605L1.37785 4.34418C1.49583 4.2262 1.56183 4.06688 1.56183 3.90023V2.23042C1.56183 1.86113 1.86113 1.56183 2.23042 1.56183H3.8849C4.06222 1.56183 4.2322 1.49117 4.35752 1.36585L5.52739 0.195978C5.78869 -0.0653261 6.21198 -0.0653261 6.47261 0.195978L7.64248 1.36585C7.7678 1.49117 7.93778 1.56183 8.1151 1.56183H9.76958C10.1389 1.56183 10.4382 1.86113 10.4382 2.23042V3.8849C10.4382 4.06222 10.5088 4.2322 10.6342 4.35752L11.804 5.52739C12.0653 5.78869 12.0653 6.21198 11.804 6.47261L10.6342 7.64248C10.5088 7.7678 10.4382 7.93778 10.4382 8.1151V9.76958C10.4382 10.1389 10.1389 10.4382 9.76958 10.4382H8.1151C7.93778 10.4382 7.7678 10.5088 7.64248 10.6342L6.47261 11.804C6.21131 12.0653 5.78802 12.0653 5.52739 11.804L4.35752 10.6342C4.23153 10.5068 4.06155 10.4368 3.88424 10.4368Z"
      fill={color}
    />
    <path d="M8.25 4.74951L5.49983 7.49923L4.25 6.24951" stroke="#323232" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default VerifiedIcon;

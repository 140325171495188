import { useState } from "react";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/client";
import { CREATE_STASHLINK } from "../../../queries";
import { useAuth } from "../../../store/contextAPI/authContext";
import mixpanel from "../../../services/mixpanelService";

const useCreateStashlink = (
  thankYouMessage: string,
  thankYouMessageVideo: Blob | null,
  parentStashlinkId: string | null,
  campaignId: string | null
) => {
  const history = useHistory();
  const { user } = useAuth();
  const [errorMessage, setErrorMessage] = useState<string>();

  const [createStashlink, loading] = useMutation(CREATE_STASHLINK);

  const createColink = async () => {
    mixpanel.track("publish-button-clicked");
    try {
      const { data } = await createStashlink({
        variables: {
          input: {
            userId: user.id,
            campaignId: campaignId,
            parentStashlinkId: parentStashlinkId,
            thankYouMessage: thankYouMessage,
            thankYouMessageVideo: thankYouMessageVideo
          }
        }
      });
      if (data.createStashlink.success && data.createStashlink.stashlink.id) {
        const stashlinkId = data.createStashlink.stashlink.id;
        history.replace(`/stashlink/${stashlinkId}`);
      } else {
        setErrorMessage("Failed to Create link");
        console.error("Error creating stashlink:", data.createStashlink.error);
      }
    } catch (err) {
      setErrorMessage("Failed to Create link");
      console.error("Mutation error:", err);
    }
  };
  return {
    user,
    createColink,
    loading,
    errorMessage
  };
};

export default useCreateStashlink;

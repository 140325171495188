import React from "react";
import { useParams } from "react-router-dom";
import UserCard from "../../components/userCard/userCard";
import CampaignCard from "../../components/campaignCard/campaignCard";
import Loader from "../../components/common/loader/loader";
import useUser from "../../services/hooks/useUser";
import { Stashlink } from "../../types";
import useUserStashlinks from "../../services/hooks/useUserStashlinks";

const CelebrityCampaigns: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const { activeUserStashlinks, userStashlinksLoading } = useUserStashlinks(userId);
  const { userData } = useUser(userId);

  if (userStashlinksLoading)
    return (
      <div className="h-[calc(100vh-80px)] w-screen flex justify-center items-center  bg-soft-black">
        <Loader />
      </div>
    );

  return (
    <div className="relative bg-soft-black text-white min-h-screen">
      <UserCard user={userData} />
      <p className="text-lg mt-2 text-center text-base-white px-4">{userData?.description}</p>
      <div className="p-4 mt-10">
        {activeUserStashlinks?.map((stashlink: Stashlink, index: number) => <CampaignCard stashlink={stashlink} key={index} />)}
        {/* {!!inActiveUserCampaigns.length && (
          <div className="mb-4">
            <h2 className="text-2xl font-bold font-primary mb-2">Previous</h2>
            <div className="flex space-x-4 overflow-x-auto">
              {inActiveUserCampaigns?.map((campaign: Campaign, index: number) => <CampaignCard campaign={campaign} key={index} />)}
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default CelebrityCampaigns;

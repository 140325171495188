import React from "react";
import sHeadToHeadGradient from "./../../assets/images/s-head-top-head-gradient.svg";
import charityLogo from "./../../assets/images/charity-logo.svg";
import { HeadToHeadImageCardProps } from "./headToHeadImageCard.types";

const HeadToHeadImageCard: React.FC<HeadToHeadImageCardProps> = (props: HeadToHeadImageCardProps) => {
  const player1 = props.player1Stashlink?.user;
  const player2 = props.player2Stashlink?.user;
  return (
    <div className="relative overflow-hidden grid grid-cols-12">
      <div className="relative col-span-6 h-72">
        <img src={player1?.profilePhoto} alt={player1?.fullName} className="w-full h-full object-cover" />
        <img
          src={charityLogo}
          alt="charity-logo"
          className="absolute -bottom-2 left-4 w-18 h-18 border-8 border-modal-bottom-bg rounded-full"
        />
      </div>
      <div className="relative col-span-6 h-72">
        <img src={player2?.profilePhoto} alt={player2?.fullName} className="w-full h-full object-cover" />
        <img
          src={charityLogo}
          alt="charity-logo"
          className="absolute -bottom-2 right-4 w-18 h-18 border-8 border-modal-bottom-bg rounded-full"
        />
      </div>
      <img src={sHeadToHeadGradient} alt="s-head-to-head" className="absolute left-2/4 -translate-x-2/4 top-2/4 -translate-y-2/4 w-20" />
    </div>
  );
};
export default HeadToHeadImageCard;

import { socialMediaIconSize } from "../../common/constants";
const FaceBookIcon = ({ color = "#FAFAFA", width = "28", height = "29", className = "" }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 28 29"
    style={{ height: socialMediaIconSize.height, width: socialMediaIconSize.width }}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M11.375 14.2254H16.625" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M16.625 9.55811H15.4583C14.1697 9.55811 13.125 10.6028 13.125 11.8914V20.0581"
      stroke={color}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="3.5" y="4.30811" width="21" height="21" rx="5.83333" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default FaceBookIcon;

import React from "react";
import suggestCelebrityImage from "../../assets/images/suggest-celebrity.svg";

const SuggestCelebrity: React.FC = () => (
    <a
      className={`relative flex justify-end items-center p-3 ps-20 mb-8 bg-grey-op70 shadow-custom shadow-black rounded-2xl`}
      href="https://www.stashrun.com/suggest"
      target="_blank"
      rel="noreferrer"
    >
      <div className="absolute -left-8 bg-primary-l6-light rounded-full w-24 h-24">
        <img src={suggestCelebrityImage} alt="Logo" className="absolute left-2/4 -translate-x-2/4 bottom-0.5 w-20 h-20 rounded-full" />
      </div>
      <div className="flex-1">
        <div className="font-semibold flex items-center text-2xl text-base-white font-primary">Suggest a celeb</div>
        <div className="flex items-center text-xs text-gray-300 mt-1">
          <span>Tell us who you&apos;d like to see giving more</span>
        </div>
      </div>
      <div className="text-gray-400">
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
        </svg>
      </div>
    </a>
    );

export default SuggestCelebrity;

import { gql } from '@apollo/client';

export const FETCH_FEATURED_USERS = gql`
  query {
        featuredUsers {
          id
          username
          fullName
          impactScore
          description
          profilePhoto
          impactLevel
          haloColor
          tiktokUsername
          instagramUsername
          twitterUsername
          youtubeUsername
          linkedinUrl
          facebookUsername
          isPublicProfile
        } 
    }
`;

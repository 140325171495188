import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_WAITLIST } from '../../../queries';
import useAuthStatus from './useAuthStatus';
import { setLocalStorage } from '../../../utils/common-function';

const useWaitlist = () => {
  const [isWaitlistModalOpen, setWaitlistModalOpen] = useState(false);
  const [createWaitlist] = useMutation(CREATE_WAITLIST);

  const { currentUser } = useAuthStatus();

  const handleJoinWaitlist = async () => {
    if (currentUser?.user?.email) {
      try {
        await createWaitlist({
          variables: {
            email: currentUser.user.email,
            accessAllowed: true
          }
        });
        setLocalStorage('isUserJoinedWaitlist', true);
      } catch (error) {
        console.error('Error creating waitlist:', error);
      }
    } else {
      setWaitlistModalOpen(true);
    }
  };

  return { handleJoinWaitlist, isWaitlistModalOpen, setWaitlistModalOpen };
};

export default useWaitlist;

import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa";
import {ReactComponent as MetaIcon}  from "./../../assets/images/Meta.svg";
type Ilogo = "google" | "meta" | "apple";

export interface ISSOButton {
  logo: Ilogo;
  onClickHandler:()=>void;
}

export const icons = {
  google: FcGoogle,
  meta: MetaIcon,
  apple: FaApple,
};

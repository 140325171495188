import { useQuery, QueryHookOptions } from "@apollo/client";
import { FOLLOWING_ENTITY } from "../../queries";

interface UseFollowingEntityResponse {
  error: string;
  following: boolean;
  success: boolean;
}

interface UseFollowingEntityResult {
  loading: boolean;
  error?: Error;
  isUserFollowing?: boolean;
  refetch: () => void;
}

const useFollowingEntity = (
  userId: string,
  entityId?: string,
  options?: QueryHookOptions<{ followingEntity: UseFollowingEntityResponse }>
): UseFollowingEntityResult => {
  const { loading, error, data, refetch } = useQuery<{ followingEntity: UseFollowingEntityResponse }>(FOLLOWING_ENTITY, {
    variables: { entityType: "User", entityId, userId },
    fetchPolicy: "network-only",
    ...options
  });

  return {
    loading,
    error,
    isUserFollowing: data?.followingEntity.following,
    refetch
  };
};

export default useFollowingEntity;

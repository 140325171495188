import React from "react";
import { IoFlash } from "react-icons/io5";
import { VscVerifiedFilled } from "react-icons/vsc";
import { useHistory } from "react-router-dom";
import { CelebrityCardProps } from "./celebrityCard.types";
import NumberImage from "../common/numberImage/numberImage";
import useUserCampaigns from "../../services/hooks/useUserCampaigns";
import { lightenColor } from "../../utils/common-function";

const CelebrityCard: React.FC<CelebrityCardProps> = (props: CelebrityCardProps) => {
  const history = useHistory();
  const { activeUserCampaigns } = useUserCampaigns(props.user?.id || "");
  // This  is temporary function will replace by hook in future
  const navigateFunction = () => {
    const route = activeUserCampaigns.length === 1 ? `/campaign/${activeUserCampaigns[0]?.id}` : `/users/${props.user?.id}/campaigns`;
    history.push(route);
  };
  const profileBackgroundColor = lightenColor(props.user?.haloColor || "#90a8c1", 0.5);

  return (
    <div
      className={`relative flex justify-end items-center p-3 ps-20 mb-8 rounded-2xl shadow-custom shadow-black`}
      style={{ backgroundColor: props.user?.haloColor }}
      onClick={() => navigateFunction()}>
      <div className={`absolute -left-8  rounded-full w-24 h-24`} style={{ backgroundColor: profileBackgroundColor }}>
        <img
          src={props.user.profilePhoto}
          alt={props.user.fullName}
          className="absolute left-2/4 -translate-x-2/4 bottom-0.5 w-20 h-20 rounded-full"
        />
        {activeUserCampaigns.length === 1 ? (
          <img
            src={activeUserCampaigns[0].charity?.imageUrl}
            alt="Logo"
            className="w-14 h-14 rounded-full absolute -bottom-2 -right-2 border-4 border-soft-black"
          />
        ) : (
          <NumberImage
            number={activeUserCampaigns.length}
            imageClassName="absolute bottom-0 right-0 w-10 h-10 rounded-full border-4 border-soft-black"
            backGroundColor={props.user?.haloColor}
          />
        )}
      </div>
      <div className="flex-1">
        <div className="font-bold flex items-center text-xl text-base-white font-primary truncate">
          {props.user.fullName} {props.user.isPublicProfile && <VscVerifiedFilled className="ml-2 text-base-white/60" />}
        </div>
        <div className="flex items-center mt-2">
          <div className="relative w-fit flex items-center text-white bg-black/40 px-2 py-1 ps-6 me-2 rounded-2xl">
            <span className="absolute left-0.5 text-2xl mr-1 text-base-white">
              <IoFlash />
            </span>
            <span className="text-xs">{props.user.impactScore}</span>
          </div>
          <span className="text-xs text-base-white">
            Supporting {activeUserCampaigns.length === 1 ? activeUserCampaigns[0].title : `${activeUserCampaigns.length} Charities`}
          </span>
        </div>
      </div>
      <div className="text-gray-400">
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
        </svg>
      </div>
    </div>
  );
};

export default CelebrityCard;

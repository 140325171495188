import React from "react";
import sHeadToHeadGradient from "./../../assets/images/s-head-top-head-gradient.svg";
import { Stashlink } from "../../types";
import { formatCurrencyToUsd }  from "../../utils/format";
interface HeadToHeadResultProps {
  player1Stashlink?: Stashlink;
  player2Stashlink?: Stashlink;
}

const HeadToHeadResult: React.FC<HeadToHeadResultProps> = (props: HeadToHeadResultProps) => {
  const player1 = props.player1Stashlink?.user;
  const player2 = props.player2Stashlink?.user;
  return (
    <section className="rounded-lg p-6 relative items-center flex w-screen justify-center">
      <h2 className="text-xl text-center font-bold mb-4 text-gray-300">And the winner is...</h2>
      <div className="flex justify-center space-x-4">
        <div className="relative bg-gradient-to-b from-green-700 to-green-800 rounded-lg p-4 w-60 shadow-lg border-4 border-lime-400">
          <div className="absolute top-0 left-0 bg-lime-400 text-black text-xs px-2 py-1 rounded-br-lg font-bold">Winner</div>
          <img src={player1?.profilePhoto} alt={player1?.fullName} className="rounded-lg mb-2 h-56 w-full object-cover" />
          <div className="text-left">
            <h3 className="text-lg font-bold">{player1?.fullName}</h3>
            <p className="text-lime-400 text-2xl font-bold">{formatCurrencyToUsd(props.player1Stashlink?.campaign.currentAmount)}</p>
            <p className="text-sm mt-1 text-gray-300">{props.player1Stashlink?.campaign.title}</p>
          </div>
          <div className="absolute top-4 right-4">
            <img src={sHeadToHeadGradient} alt="Winner Badge" className="w-8 h-8" />
          </div>
        </div>

        <div className="relative bg-gradient-to-b from-gray-700 to-gray-800 rounded-lg p-4 w-52 shadow-lg border-4 border-gray-600">
          <div className="absolute top-0 right-0 bg-gray-600 text-white text-xs px-2 py-1 rounded-bl-lg font-bold">2nd</div>
          <img src={player2?.profilePhoto} alt={player2?.fullName} className="rounded-lg mb-2 h-48 w-full object-cover" />
          <div className="text-left">
            <h3 className="text-lg font-bold">{player2?.fullName}</h3>
            <p className="text-lime-400 text-2xl font-bold">{formatCurrencyToUsd(props.player2Stashlink?.campaign.currentAmount)}</p>
            <p className="text-sm mt-1 text-gray-300">{props.player1Stashlink?.campaign.title}</p>
          </div>
          <div className="absolute top-4 right-4">
            <img src="/path_to_runner_up_badge.png" alt="Runner Up Badge" className="w-8 h-8" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeadToHeadResult;

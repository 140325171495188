import React, { useEffect } from "react";
import SocialMediaButtons from "../common/social-media-buttons/social-media-buttons";
import { UserProfileProps } from "./userProfile.types";
import { generateProfileImageUrl, getStyles, splitName } from "../../utils/common-function";
import FlashIcon from "../svgImages/flashIcon";
import mixpanel from "../../services/mixpanelService";
import VerifiedIcon from "../svgImages/verifiedIcon";

const UserProfile: React.FC<UserProfileProps> = (props: UserProfileProps) => {
  const styles = getStyles(props.impactScoreLevel);
  const { firstName, lastName } = splitName(props.user?.fullName || "");

  useEffect(() => {
    mixpanel.track("user-profile-viewed");
  }, []);

  useEffect(() => {
    if (props.isOpen) {
      document.body.classList.add('prevent-scroll');
    } else {
      document.body.classList.remove('prevent-scroll');
    }
    return () => {
      document.body.classList.remove('prevent-scroll');
    };
  }, [props.isOpen]);

  return (
    <div className="relative pt-32">
      <div className="flex justify-center items-center">
        <div className="absolute -top-12 w-[164px] h-[168px] rounded-full" style={{ background: styles.levelColor }}>
          <div
            className="absolute bottom-[2px] left-2/4 -translate-x-2/4 w-[148px] h-[148px] rounded-full mx-auto"
            style={{
              backgroundImage: `url(${generateProfileImageUrl(props.user?.profilePhoto || "", "296", "296")})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat"
            }}></div>
          <div className="absolute -bottom-3 left-2/4 -translate-x-2/4 w-fit flex items-center text-base-white text-sm bg-gradient-to-r
          from-black/0 to-black/0 bg-black/[0.25] space-x-2 px-2 py-px pt-c-3 ps-7 rounded-2xl border-b-3 border-r-3 border-black/40 backdrop-blur-10.5 min-w-c-72">
            <span className="absolute left-0 -top-0.5 text-3xl mr-1 text-base-white">
              <FlashIcon color={styles.levelColor} className="h-8 w-auto relative left-[6px] -top-0.5" />
            </span>
            <span className="text-sm font-medium leading-tight-14.40 text-neutral-50 font-secondary !ml-[1px]">{props.user?.impactScore}</span>
          </div>
        </div>
      </div>
      <h2 className="text-center text-xl font-bold mb-1 mt-2 px-6" style={{ color: styles.levelColor }}>
        {firstName}{" "}
        <span className="inline-block">
          {lastName}
          {props.user?.isPublicProfile && (
            <VerifiedIcon color={styles.levelColor} className="ml-1.5 w-3 h-3 inline-block align-middle relative -top-2" />
          )}
        </span>
      </h2>
      <p className="text-center text-sm mt-2 text-base-white/90 px-6">{props.user?.description}</p>
      <div className="flex justify-center space-x-4 mb-4 mt-4">
        <SocialMediaButtons
          screen="user"
          instagramUsername={props.user?.instagramUsername}
          youtubeUsername={props.user?.youtubeUsername}
          tiktokUsername={props.user?.tiktokUsername}
          impactScoreLevel={props.impactScoreLevel}
          useLightColorIcon={false}
        />
      </div>
    </div>
  );
};

export default UserProfile;

import { gql } from "@apollo/client";

export const FETCH_USER_STASHLINKS = gql`
  query FetchUser($userId: ID!) {
    user(id: $userId) {
      id
      fullName
      impactLevel
      percentToNextLevel
      profilePhoto
      haloColor
      impactScore
      stashlinks {
        id
        url
        numViews
        numShares
        dollarsDonated
        thankYouMessage
        thankYouMessageVideo
        user {
          id
          fullName
          profilePhoto
          isPublicProfile
          description
          email
          impactScore
          impactLevel
          instagramUsername
          twitterUsername
          youtubeUsername
        }
        campaign {
          id
          title
          description
          currentAmount
          imageUrl
          currentAmount
          goalAmount
          endTime
          canSupport
          cause {
            id
            name
          }
          charity {
            id
            name
            charityColor
            imageUrl
          }
        }
        parentStashlink {
          thankYouMessage
          thankYouMessageVideo
          url
          id
          user {
            id
            fullName
            profilePhoto
            isPublicProfile
            description
            email
            impactScore
            impactLevel
            instagramUsername
            twitterUsername
            youtubeUsername
          }
        }
      }
    }
  }
`;

// import { IPost, IAction } from "../../common/interfaces";

const initialState = {
  items: Array,
  item: {}
};

const posts = (state = initialState, action: any) => {
  switch (action.type) {
    case "FETCH_POSTS":
      return {
        ...state,
        items: action.payload
      };
    default:
      return state;
  }
};

export default posts;

import { SHOW_SPINNER, HIDE_SPINNER } from "../../actions";

const initUi = {
  pending: false
};

const ui = (state = initUi, action: any) => {
  switch (action.type) {
    case SHOW_SPINNER:
      return { ...state, pending: true };

    case HIDE_SPINNER:
      return { ...state, pending: false };

    default:
      return state;
  }
};

export default ui;

import { gql } from "@apollo/client";

export const GET_STASHLINK = gql`
  query stashlinkByUserAndCampaign($campaignId: ID, $campaignSlug: String, $userId: ID!) {
    stashlinkByUserAndCampaign(userId: $userId, campaignId: $campaignId, campaignSlug: $campaignSlug) {
      thankYouMessage
      thankYouMessageVideo
      url
      id
      user {
        id
        fullName
        profilePhoto
        haloColor
        isPublicProfile
      }
      campaign {
        id
        endTime
        title
        description
        imageUrl
        videoUrl
        currentAmount
        goalAmount
        location
        cause {
          id
          name
          description
          tagline
          imageUrl
          coinsReceived
          numCampaigns
          numSupporters
          numCharities
          hashTags
          videoUrl
          wikipediaUrl
          website
          location
          heroTagline
          heroImageUrl
          numComments
          dollarsReceived
        }
        charity {
          id
          name
          imageUrl
          videoUrl
          activeSince
          city
          location
          country
          registeredNonProfit
          description
          fullAddress
          numEmployees
          postalCode
          registeredName
          stateProvince
          streetAddress
          yearFounded
          website
          numSupporters
          numCauses
          totalCoinDrops
          coinBalance
          heroImageUrl
          headerLogoUrl
          heroTagline
          numCampaigns
          coinsGiven
          username
          volunteerUrl
          instagramUsername
          twitterUsername
          facebookUsername
          youtubeUsername
          tiktokUsername
          linkedinUrl
          charityColor
          instagramData
          studentOrgs
          dollarsReceived
        }
        totalCoinDrops
        numSupporters
        canSupport
        disclosuresUrl
        shareUrl
        studentOrgs
        dollarsReceived
        slug
      }
      parentStashlink {
        thankYouMessage
        thankYouMessageVideo
        url
        id
        user {
          id
          fullName
          profilePhoto
          haloColor
          isPublicProfile
        }
        campaign {
          id
          endTime
          title
          description
          imageUrl
          videoUrl
          currentAmount
          goalAmount
          location
          cause {
            id
            name
            description
            tagline
            imageUrl
            coinsReceived
            numCampaigns
            numSupporters
            numCharities
            hashTags
            videoUrl
            wikipediaUrl
            website
            location
            heroTagline
            heroImageUrl
            numComments
            dollarsReceived
          }
          charity {
            id
            name
            imageUrl
            videoUrl
            activeSince
            city
            location
            country
            registeredNonProfit
            description
            fullAddress
            numEmployees
            postalCode
            registeredName
            stateProvince
            streetAddress
            yearFounded
            website
            numSupporters
            numCauses
            totalCoinDrops
            coinBalance
            heroImageUrl
            headerLogoUrl
            heroTagline
            numCampaigns
            coinsGiven
            username
            volunteerUrl
            instagramUsername
            twitterUsername
            facebookUsername
            youtubeUsername
            tiktokUsername
            linkedinUrl
            charityColor
            instagramData
            studentOrgs
            dollarsReceived
          }
          totalCoinDrops
          numSupporters
          canSupport
          disclosuresUrl
          shareUrl
          studentOrgs
          dollarsReceived
          slug
        }
      }
    }
  }
`;

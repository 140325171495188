import React, { useCallback, useMemo, useState } from "react";
import { useAuth } from "../../../../store/contextAPI/authContext";
import { IPasswordErrors } from "./types";
import { getLocalStorage, setLocalStorage } from "../../../../utils/common-function";
import { useMutation } from "@apollo/client";
import { SYNC_SUPABASE_USER } from "../../../../queries";

const useSetYourPassword = () => {
  const { user, setPasswordContext, handleSignUp } = useAuth();

  const [password, setPassword] = useState({
    value: "",
    error: {
      isValidLength: false,
      hasLetterAndNumber: false,
      hasSpecialCharacters: false
    }
  });

  const validatePassword = useCallback((input: string): IPasswordErrors => {
    const validLength = /^.{8,20}$/;
    const letterNumber = /^(?=.*[A-Za-z])(?=.*\d)/;
    const specialCharacters = /(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?`~])/;

    return {
      isValidLength: validLength.test(input),
      hasLetterAndNumber: letterNumber.test(input),
      hasSpecialCharacters: specialCharacters.test(input)
    };
  }, []);

  const isContinueDisable = useMemo((): boolean => {
    const error = validatePassword(password.value);
    return !(error.isValidLength && error.hasLetterAndNumber && error.hasSpecialCharacters);
  }, [validatePassword, password.value]);

  const onChangePassword = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let { value } = event.target;
      value = value.replace(/\s+/g, "");
      if (value.length > 20) {
        value = value.slice(0, 20);
      }
      const emailError = validatePassword(value);
      setPassword({ value, error: emailError });
    },
    [validatePassword]
  );

  const [syncSupabaseUser] = useMutation(SYNC_SUPABASE_USER);

  const handleSyncUser = async () => {
    try {
      const result = await syncSupabaseUser({
        variables: {
          input: {}
        }
      });
      setLocalStorage("userId", result.data.syncSupabaseUser.user.id);
    } catch (e) {
      console.error("Error syncing user:", e);
    }
  };

  const onClickContinueHandler = useCallback(async () => {
    const finalEmail: string = user.email ? user.email : getLocalStorage("email");
    await handleSignUp({
      email: finalEmail,
      password: password.value
    });
    await handleSyncUser();
    setPasswordContext(password.value);
  }, [password.value, setPasswordContext]);

  const classForCharacterLengthText = useMemo((): string => {
    if (password.value.length === 0) return "";
    return password.error.isValidLength ? "" : "text-error";
  }, [password.error.isValidLength, password.value.length]);

  const classForLetterAndCharacterText = useMemo((): string => {
    if (password.value.length === 0) return "";
    return password.error.hasLetterAndNumber ? "" : "text-error";
  }, [password.error.hasLetterAndNumber, password.value.length]);

  const classForSpecialCharacterText = useMemo((): string => {
    if (password.value.length === 0) return "";
    return password.error.hasSpecialCharacters ? "" : "text-error";
  }, [password.error.hasSpecialCharacters, password.value.length]);

  const classForErrorHeading = useMemo((): string => {
    if (password.value.length === 0) return "";
    return !isContinueDisable ? "" : "text-error";
  }, [isContinueDisable, password.value.length]);
  return {
    password,
    classForCharacterLengthText,
    classForErrorHeading,
    classForLetterAndCharacterText,
    classForSpecialCharacterText,
    onClickContinueHandler,
    onChangePassword,
    isContinueDisable
  };
};

export default useSetYourPassword;

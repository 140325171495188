import React from "react";
import ImpactBadge from "../common/impactBadges/impactBadges";
import { formatNumberWithUnits, generateCloudinaryImageUrl, getStyles, splitName } from "../../utils/common-function";
import { UserCardProps } from "./userCard.types";
import ShareIcon from "./../../assets/images/shareIconArrow.svg";
import VerifiedIcon from "../svgImages/verifiedIcon";
import mixpanel from "../../services/mixpanelService";

const UserCard: React.FC<UserCardProps> = (props: UserCardProps) => {
  const impactScore = formatNumberWithUnits(props.user?.impactScore || 0);

  const openShareModal = () => {
    props.setIsShareModalOpen && props.setIsShareModalOpen(true);
  };

  const { firstName, lastName } = splitName(props.user?.fullName || "");

  const styles = getStyles(props.impactScoreLevel);

  return (
    <div className="relative w-full flex flex-col items-center">
      <div className="relative w-full aspect-video">
        {!props.showIcons && (
          <div className="absolute w-full top-4 flex justify-center z-10">
            <div className="font-inter font-extrabold inter-I text-white text-13.77px backdrop-blur-5 tracking-0.964px w-105.93 h-33.105 py-8.21 px-17.87 flex items-center previewRotate justify-center leading-none rounded-8.21 border-1.39 border-white bg-black/30 shadow-previewShadow text-shadow-custom uppercase">
              Preview
            </div>
          </div>
        )}
        <img
          src={generateCloudinaryImageUrl(props.user?.profilePhoto || "", "750", "496")}
          alt={props.user?.fullName}          
        />
        <div className="h-20 absolute bottom-0 w-full bg-gradient-to-t from-black from-0% via-black/40 via-40% to-black/0 to-100%"></div>
        {/* <div className="absolute bottom-0 h-full w-full" style={{height: "26%", background: "linear-gradient(hsla(0, 0%, 0%, 0) 0%,  hsla(0, 0%, 0%, 0.002) 1.8%,  hsla(0, 0%, 0%, 0.008) 4.8%,  hsla(0, 0%, 0%, 0.021) 9%,  hsla(0, 0%, 0%, 0.042) 13.9%,  hsla(0, 0%, 0%, 0.075) 19.8%,  hsla(0, 0%, 0%, 0.126) 27%,  hsla(0, 0%, 0%, 0.194) 35%,  hsla(0, 0%, 0%, 0.278) 43.5%,  hsla(0, 0%, 0%, 0.382) 53%,  hsla(0, 0%, 0%, 0.541) 66%,  hsla(0, 0%, 0%, 0.738) 81%,  hsl(0, 0%, 0%) 100%)"}} /> */}
      </div>
      <div className="text-center">
        {props.showIcons && (
          <>
            <span onClick={() => mixpanel.track("hero-impact-score-clicked")} className="absolute top-3 left-0">
              <ImpactBadge
                impact={impactScore}
                isImpactIconRequired
                setIsUserProfileModalOpen={props.setIsUserProfileModalOpen}
                impactScoreLevel={props.impactScoreLevel}
              />
            </span>
            <div className="p-0.5 bg-black/40 rounded-full flex justify-center items-center absolute top-3 right-3 backdrop-filter-blur h-8 w-8">
              <img
                src={ShareIcon}
                alt={ShareIcon}
                onClick={() => {
                  mixpanel.track("hero-share-clicked");
                  openShareModal();
                }}
              />
            </div>
          </>
        )}
        <div className="w-full h-9 py-2 pb-0 left-0 bottom-0 absolute justify-center items-end gap-1 inline-flex">
          <h1
            onClick={() => mixpanel.track("hero-name-clicked")}
            className="break-words w-fit text-28px font-primary font-extrabold z-10 px-2 -mt-10 items-start inline-block  capitalize leading-tight-29.54"
            style={{ color: styles.levelColor }}>
            {firstName}{" "}
            <span className="inline-block">
              {lastName}
              {props.user?.isPublicProfile && (
                <VerifiedIcon color={styles.levelColor} className="ml-1.5 w-3 h-3 inline-block align-middle relative -top-2" />
              )}
            </span>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default UserCard;

import { combineReducers } from "redux";
// import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { configureStore } from "@reduxjs/toolkit";
// import logger from "redux-logger";

import { users, posts, ui, attendees } from "./reducers";

const initialState = {};

const store = configureStore({
  reducer: combineReducers({
    users,
    posts,
    ui,
    attendees
  }),
  middleware: [],
  ...initialState
});

export default store;

import React from "react";
import mixpanel from "../../services/mixpanelService";

const HeadToHeadInfoCard: React.FC = () => (
  <div className="p-4 bg-gradient-to-t from-soft-black from-5% via-soft-black via-90% to-modal-bottom-bg to-100%">
    <div className="bg-grey/5 p-6 border-2 border-black rounded-3xl mt-8">
      <div>
        <h2 className="font-primary text-2xl text-base-white mb-2">How it Works</h2>
        <p className="font-secondary text-grey text-base mb-3">
          Help your favorite celebs and influencers win Head to Head fundraising challenges.
        </p>
        <p className="font-secondary text-grey text-base mb-3">
          Choose a team to play and share for, helping them reach the donation goal, or raise the most money before time runs out.
        </p>
        <p className="font-secondary text-grey text-base mb-3">Let’s see who has the most impactful fanbase!</p>
      </div>
      <div className="mt-6">
        <h2 className="font-primary text-2xl text-base-white mb-2">Suggest a match</h2>
        <p className="font-secondary text-grey text-base mb-3">Tell us what celebs or influencers you want to see go head to head! 🤺</p>
        <a
          onClick={() => mixpanel.track('suggest-clicked', { location: 'head-to-head-info' })}
          href="https://www.stashrun.com/suggest"
          target="_blank"
          rel="noreferrer"
          className="font-secondary text-primary-l1"
        >
          Suggest a match
        </a>
      </div>
    </div>
  </div>
);

export default HeadToHeadInfoCard;

import InputField from "../../../components/common/input/input";
import Button from "../../../components/common/button";
import { buttonTitle, heading, resetNewPasswordAttributes, subHeading } from "../Onboarding/constant";
import OnboardingWrapper from "../../../containers/onboardingWrapper";
import useCreateYourPassword from "./hooks/useCreatePassword";
import { routes } from "../../../routes/routes.constant";
import { inputType } from "../../../common/constants";
import { withAuthGuard } from "../../../routes/helpers";

const CreatePassword = () => {
  const { password, onChangePassword, classForText, onClickContinueHandler, isContinueDisable } = useCreateYourPassword();
  return (
    <OnboardingWrapper heading={heading.createNewPassword} backButtonPath={routes.onboarding}>
      <div className="w-full">
        <p className="mx-6 text-sm text-gray-400 text-center font-secondary">{subHeading.newPassword}</p>
      </div>
      <div className="w-full p-4">
        <div className="w-full">
          <InputField
            label={resetNewPasswordAttributes.label}
            onChange={onChangePassword}
            inputClassName={resetNewPasswordAttributes.className}
            name={resetNewPasswordAttributes.name}
            type={inputType.password}
            placeholder={resetNewPasswordAttributes.placeHolder}
            value={password.value}
            error={classForText(password.value, !isContinueDisable)}
          />
          <div className="text-xs text-grey mb-3 -mt-10 leading-none">
            <p className={classForText(password.value, !isContinueDisable)}>
              <strong className="mb-1">Your password must have at least:</strong>
            </p>
            <ul style={{ listStyleType: "disc" }} className="mx-4 leading-16.8 font-normal">
              <li className={classForText(password.value, password.error.isValidLength)}><span className="inline-block mb-1 mt-1">8 characters (20 max)</span></li>
              <li className={classForText(password.value, password.error.hasLetterAndNumber)}><span className="inline-block mb-1">1 letter and 1 number</span></li>
              <li className={classForText(password.value, password.error.hasSpecialCharacters)}>
              <span className="inline-block mb-1">1 special character (Example: # ? ! $ & @)</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fixed w-full bg-black/70 backdrop-blur-8 left-0 bottom-0 p-4 pb-5 z-10 select-none">
        <Button title={buttonTitle.resetPassword} isDisabled={isContinueDisable} onClick={onClickContinueHandler} />
      </div>
    </OnboardingWrapper>
  );
};

export default withAuthGuard(CreatePassword);

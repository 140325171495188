const FlashIcon = ({ color = "#D7FF3E", width = "20", height = "32", className = "" }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 18 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      id="image 1311 (Traced)"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2259 0.0988154C10.928 0.256227 0.262209 17.1721 0.218206 17.557C0.110605 18.4985 0.20964 18.5207 4.51772 18.5207C7.93231 18.5207 8.13056 18.5264 8.1024 18.6233C7.91571 19.267 5.48413 29.0648 5.48413 29.1735C5.48413 29.7857 6.20284 30.1945 6.77018 29.9051C7.12895 29.722 17.8048 12.7482 17.8048 12.3609C17.8048 11.4895 17.7597 11.4803 13.4881 11.4803H9.87071L9.90668 11.3483C10.2092 10.2377 12.5245 0.931401 12.5245 0.825913C12.5245 0.213457 11.7887 -0.198583 11.2259 0.0988154Z"
      fill={color}
    />
  </svg>
);

export default FlashIcon;

import { useLazyQuery } from "@apollo/client";
import { Campaign, Stashlink, User } from "../../../types";
import { FETCH_STASHLINK } from "../../../queries";

const useStashLinkHooks = () => {
  const [getStashLink, { data, loading: stashlinkLoading }] = useLazyQuery<{
    stashlinkById: {
      thankYouMessage: string;
      thankYouMessageVideo: string;
      user: User;
      id: string;
      url: string;
      campaign: Campaign;
      parentStashlink: Stashlink;
    };
  }>(FETCH_STASHLINK, {
    fetchPolicy: "network-only"
  });


  let stashlink = data?.stashlinkById;
  if (stashlink) {
    stashlink = {
      ...stashlink,
      campaign: {
        ...stashlink?.campaign,
        currentAmount: stashlink.campaign.currentAmount / 100,
        goalAmount: stashlink.campaign.goalAmount / 100
      }
    };
  }

  return {
    getStashLink,
    stashlink,
    stashlinkLoading
  };
};

export default useStashLinkHooks;

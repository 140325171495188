import { useQuery, ApolloError } from "@apollo/client";
import { STASHLINK_EXIST } from "../../queries";

interface UseStashlinkExistResponse {
    hasCreatedStashlinkBefore: boolean;
}

interface UseStashlinkExistHook {
  stashlinkExistLoading: boolean;
  stashlinkExistError?: ApolloError;
  stashlinkExistData?: boolean;
}

const useStashlinkExist = (campaignId: string): UseStashlinkExistHook => {
  const {
    loading: stashlinkExistLoading,
    error: stashlinkExistError,
    data
  } = useQuery<UseStashlinkExistResponse>(STASHLINK_EXIST, {
    variables: {
        campaignId
    },
    fetchPolicy: "network-only"
  });
  const stashlinkExistData = data?.hasCreatedStashlinkBefore;
  return { stashlinkExistLoading, stashlinkExistError, stashlinkExistData };
};

export default useStashlinkExist;

import React, { useCallback } from "react";
import { IoIosArrowBack } from "react-icons/io";
import TabWrapper from "../components/tabWrapper";
import { useHistory, useLocation } from "react-router";
import { OnboardingWrapperProps } from "../common/interfaces";

const OnboardingWrapper: React.FC<OnboardingWrapperProps> = (props: OnboardingWrapperProps) => {
  const history = useHistory();
  const location = useLocation();
  const { heading, children, isTabVisible, tabId, totalTab, backButtonPath, onBackHandler } = props;

  const onBackButtonHandler = useCallback(() => {
    onBackHandler && onBackHandler();
    if (backButtonPath) {
        const newState = location.state as Record<string, any>;
        history.push({ pathname: backButtonPath, state: { ...newState, fromValidFlow: true } });
    } else {
      history.goBack();
    }
  }, [backButtonPath, history, heading]);

  return (
    <div className="w-full bg-soft-black">
      {isTabVisible ? <TabWrapper activeIndex={tabId ?? 0} totalTabs={totalTab ?? 4} /> : <></>}
      <div className="flex items-start justify-center w-full">
        <div className="w-full">
          <div className="flex items-center px-2 py-3.5 h-12">
            {tabId !== 0 && <IoIosArrowBack className="text-grey absolute text-2xl" onClick={onBackButtonHandler} />}
            <h1 className="flex-1 font-primary text-center text-base text-neutral-50 font-bold leading-tight-19.2 tracking-tight">
              {heading}
            </h1>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default OnboardingWrapper;

import mixpanel from "../services/mixpanelService";

export const saveImage = (imgRef: any) => {
  mixpanel.track("image-copied");
  const canvas = imgRef?.current?.querySelector("canvas");
  if (canvas) {
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qr-code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
};

export const sendSMS = (shareUrl: string, charityName: string) => {
  mixpanel.track("sms-button-clicked", {
    charityName,
    shareUrl,
  });
  const message =
  `Help me support ${charityName}. ` +
  `This link raises money for the charity when you PLAY and share! ` +
  `There's no cost involved :) ${shareUrl}`;

  window.location.href = `sms:?&body=${encodeURIComponent(message)}`;
};

import React from "react";
import QRCode from "qrcode.react";
import { ShareQRCodeProps } from "./shareQRCode.types";
import StashrunLogoBeta from "./../../../assets/images/Stashrun-beta-logo-grey.png";
import VerifiedIcon from "../../../assets/images/verified.svg";
import CloseIcon from "./../../../assets/images/CloseWhiteIcon.png";
import { convertHttpToHttps, generateCloudinaryImageUrl, splitName } from "../../../utils/common-function";

const ShareQRCode: React.FC<ShareQRCodeProps> = (props: ShareQRCodeProps) => {
  const { firstName, lastName } = splitName(props.stashUser?.fullName || "");
  const { firstName: colinkUserFirstName, lastName: colinkUserLastName } = splitName(props.coLinkUser?.fullName || "");
  return (
    <div
      className={`flex justify-center items-center min-h-screen bg-black/40 fixed inset-0 z-50 transition-transform duration-300 backdrop-blur ${
        props.isOpen ? "translate-y-0" : "translate-y-full"
      }`}>
      <div className="container mx-auto grid grid-cols-12 gap-4 flex items-center">
        <div className="col-span-12 bg-soft-black text-center text-white rounded-3xl shadow-lg border-r-4 border-b-4 border-primary-l1 relative max-h-screen w-c-276 mx-auto overflow-auto -mt-16">
          <div className="bg-modal-top-bg p-3 rounded-3xl">
            <div className="relative overflow-hidden">
              <div
                className="absolute top-1 right-1 w-7 h-7 p-2 rounded-full flex justify-center items-center bg-black/70"
                onClick={props.onClose}>
                <img src={CloseIcon} alt={CloseIcon} className="brightness-0 invert" />
              </div>
              {props.coLinkUser ? (
                <div className="flex w-full aspect-video">
                  <div
                    className="rounded-l-2xl w-1/2 h-full border-r-2 border-soft-black"
                    style={{
                      backgroundImage: `url(${generateCloudinaryImageUrl(convertHttpToHttps(props.stashUser.profilePhoto) || "", "248", "296")})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat"
                    }}></div>
                  <div
                    className="rounded-r-2xl w-1/2 h-full border-l-2 border-soft-black"
                    style={{
                      backgroundImage: `url(${generateCloudinaryImageUrl(convertHttpToHttps(props.coLinkUser.profilePhoto) || "", "248", "296")})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat"
                    }}></div>
                </div>
              ) : (
                <div
                  className="rounded-2xl w-full aspect-video rounded-bl-3xl"
                  style={{
                    backgroundImage: `url(${generateCloudinaryImageUrl(convertHttpToHttps(props.stashUser.profilePhoto) || "", "504", "296")})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat"
                  }}></div>
              )}
              <img
                src={generateCloudinaryImageUrl(convertHttpToHttps(props.charityLogo) || "", "120", "120")}
                alt="Logo"
                className={`absolute -bottom-2 ${
                  props.coLinkUser ? "left-2/4 -translate-x-2/4" : "-left-2"
                } w-16 h-16 rounded-full border-8 border-modal-top-bg`}
                crossOrigin="anonymous"
              />
            </div>
            <div className="text-center pb-0">
              {props.coLinkUser ? (
                <>
                  <h1 className="text-xl font-extrabold font-primary text-primary-l1 inline-block mt-3">
                    {firstName}{" "}
                    <span className="inline-block">
                      {lastName}
                      {props.stashUser?.isPublicProfile && (
                        <img
                          src={VerifiedIcon}
                          alt={VerifiedIcon}
                          className="ml-0.5 w-3 h-3 inline-block align-middle relative -translate-y-1.5"
                        />
                      )}
                    </span>
                  </h1>
                  <p className="-mt-2">
                    <span className="text-primary-l1 font-primary text-xl font-extrabold">x</span>
                  </p>
                  <h1 className="text-xl font-extrabold font-primary text-primary-l1 inline-block -mt-1">
                    {colinkUserFirstName}{" "}
                    <span className="inline-block">
                      {colinkUserLastName}
                      {props.coLinkUser?.isPublicProfile && (
                        <img
                          src={VerifiedIcon}
                          alt={VerifiedIcon}
                          className="ml-0.5 w-3 h-3 inline-block align-middle relative -translate-y-1.5"
                        />
                      )}
                    </span>
                  </h1>
                </>
              ) : (
                <p className="mt-3 text-xl font-extrabold font-primary text-primary-l1 inline-block">
                  {firstName}{" "}
                  <span className="inline-block">
                    {lastName}
                    {props.stashUser?.isPublicProfile && (
                      <img
                        src={VerifiedIcon}
                        alt={VerifiedIcon}
                        className="ml-0.5 w-3 h-3 inline-block align-middle relative -translate-y-1.5"
                      />
                    )}
                  </span>
                </p>
              )}
              <p className="text-center font-secondary font-normal text-sm leading-tight-19.2">is stashing for</p>
              <div className="mb-1">
                <h3 className="text-center text-lg font-secondary font-bold leading-tight-21.6 mt-1.5">{props.charityName}</h3>
              </div>
              <div className="flex justify-center items-center mt-[18px]">
                {props.shareUrl && <QRCode value={props.shareUrl} size={120} bgColor="transparent" fgColor="#ffffff" />}
              </div>
              <p className="text-center text-xs font-normal font-secondary leading-none text-white mt-[16px]">
                Share this QR code so friends can play
              </p>
            </div>
          </div>

          <div className="p-4 flex justify-center items-center">
            <img src={StashrunLogoBeta} alt={StashrunLogoBeta} className="h-3" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareQRCode;

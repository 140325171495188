import { History } from "history";
import { useEffect } from "react";

const useScrollToSection = (history: History<unknown>) => {
  useEffect(() => {
    const handleHashChange = () => {
      const sectionId = window.location.hash.substring(1);
      const section = document.getElementById(sectionId);

      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
        history.replace(window.location.pathname);
      }
    };

    if (window.location.hash) {
      handleHashChange();
    }

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [history]);
};

export default useScrollToSection;

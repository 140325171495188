import { gql } from '@apollo/client';

export const FETCH_HEAD_TO_HEAD = gql`
  query {
    currentHeadToHeadBattle {
      id
      player1Stashlink {
        id
        user {
          id
          username
          fullName
          impactScore
          description
          profilePhoto
          impactLevel
          haloColor
          tiktokUsername
          instagramUsername
          twitterUsername
          youtubeUsername
          linkedinUrl
          facebookUsername
        }
        campaign {
          id
          title
          slug
          currentAmount
          cause {
            id
            name
            imageUrl
          }
          charity {
            id
            name
            activeSince
            location
            registeredName
            imageUrl
            charityColor
            description
            website
            volunteerUrl
            facebookUsername
            twitterUsername
            youtubeUsername
            instagramUsername
            tiktokUsername
            linkedinUrl
          }
          disclosuresUrl
        }
      }
      player2Stashlink {
        id
        user {
          id
          username
          fullName
          impactScore
          description
          profilePhoto
          impactLevel
          haloColor
          tiktokUsername
          instagramUsername
          twitterUsername
          youtubeUsername
          linkedinUrl
          facebookUsername
        }
        campaign {
          id
          title
          slug
          currentAmount
          cause {
            id
            name
            imageUrl
          }
          charity {
            id
            name
            activeSince
            location
            registeredName
            imageUrl
            charityColor
            description
            website
            volunteerUrl
            facebookUsername
            twitterUsername
            youtubeUsername
            instagramUsername
            tiktokUsername
            linkedinUrl
          }
          disclosuresUrl
        }
      }
      player1Score
      player2Score
      goal
      status
      startTime
      endTime
    }
    nextHeadToHeadBattle {
      id
      player1Stashlink {
        id
        user {
          id
          username
          fullName
          impactScore
          description
          profilePhoto
          impactLevel
          haloColor
          tiktokUsername
          instagramUsername
          twitterUsername
          youtubeUsername
          linkedinUrl
          facebookUsername
        }
        campaign {
          id
          title
          cause {
            id
            name
            imageUrl
          }
          charity {
            id
            name
            activeSince
            location
            registeredName
            imageUrl
            charityColor
            description
            website
            volunteerUrl
            facebookUsername
            twitterUsername
            youtubeUsername
            instagramUsername
            tiktokUsername
            linkedinUrl
          }
          disclosuresUrl
        }
      }
      player2Stashlink {
        id
        user {
          id
          username
          fullName
          impactScore
          description
          profilePhoto
          impactLevel
          haloColor
          tiktokUsername
          instagramUsername
          twitterUsername
          youtubeUsername
          linkedinUrl
          facebookUsername
        }
        campaign {
          id
          title
          cause {
            id
            name
            imageUrl
          }
          charity {
            id
            name
            activeSince
            location
            registeredName
            imageUrl
            charityColor
            description
            website
            volunteerUrl
            facebookUsername
            twitterUsername
            youtubeUsername
            instagramUsername
            tiktokUsername
            linkedinUrl
          }
          disclosuresUrl
        }
      }
      player1Score
      player2Score
      goal
      status
      startTime
      endTime
    }
    recentlyFinishedHeadToHeadBattle {
      id
      player1Stashlink {
        id
        user {
          id
          username
          fullName
          impactScore
          description
          profilePhoto
          impactLevel
          haloColor
          tiktokUsername
          instagramUsername
          twitterUsername
          youtubeUsername
          linkedinUrl
          facebookUsername
        }
        campaign {
          id
          title
          currentAmount
          cause {
            id
            name
            imageUrl
          }
          charity {
            id
            name
            activeSince
            location
            registeredName
            imageUrl
            charityColor
            description
            website
            volunteerUrl
            facebookUsername
            twitterUsername
            youtubeUsername
            instagramUsername
            tiktokUsername
            linkedinUrl
          }
          disclosuresUrl
        }
      }
      player2Stashlink {
        id
        user {
          id
          username
          fullName
          impactScore
          description
          profilePhoto
          impactLevel
          haloColor
          tiktokUsername
          instagramUsername
          twitterUsername
          youtubeUsername
          linkedinUrl
          facebookUsername
        }
        campaign {
          id
          title
          currentAmount
          cause {
            id
            name
            imageUrl
          }
          charity {
            id
            name
            activeSince
            location
            registeredName
            imageUrl
            charityColor
            description
            website
            volunteerUrl
            facebookUsername
            twitterUsername
            youtubeUsername
            instagramUsername
            tiktokUsername
            linkedinUrl
          }
          disclosuresUrl
        }
      }
      player1Score
      player2Score
      goal
      status
      startTime
      endTime
    }
  }
`;

import ROUTES from "./routeConfigs";
import { generateRouteMap, displayRouteMenu } from "./helpers";

// EXPORT ROUTE INFO

// Create a flat array of routes to more easily filter/manipulate
const { pathMap } = generateRouteMap(ROUTES);
export const RouteMap = pathMap;
export const AppRoutes = ROUTES.find((r) => r.key === "APP")?.routes;

// Create route menu to display on ui
export const DisplayRouteMenu = displayRouteMenu(ROUTES);

// ROUTES
export default ROUTES;

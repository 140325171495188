import InputField from "../../../components/common/input/input";
import Button from "../../../components/common/button";
import { buttonTitle, heading, setPasswordAttributes, validationRules } from "./constant";
import useManagePassword from "./hooks/useManagePassword";
import OnboardingWrapper from "../../../containers/onboardingWrapper";
import { routes } from "../../../routes/routes.constant";
import { withAuthGuard } from "../../../routes/helpers";

const ManagePassword = () => {
  const {
    password,
    onChangePassword,
    classForCharacterLengthText,
    classForErrorHeading,
    classForLetterAndCharacterText,
    classForSpecialCharacterText,
    onClickContinueHandler,
    isContinueDisable
  } = useManagePassword();
  return (
    <OnboardingWrapper heading={heading.setYourPassword} backButtonPath={routes.onboarding}>
      <div className="w-full p-4">
        <div className="w-full">
          <InputField
            label={setPasswordAttributes.label}
            onChange={onChangePassword}
            inputClassName={setPasswordAttributes.className}
            name={setPasswordAttributes.name}
            type={setPasswordAttributes.name}
            placeholder={setPasswordAttributes.placeHolder}
            value={password.value}
            error={classForErrorHeading}
          />
          <div className="text-xs text-grey mb-3 -mt-10">
            <p className={classForErrorHeading}><strong>Your password must have at least:</strong></p>
            <ul style={{ listStyleType: "disc" }} className="mx-4 leading-tight-16.8">
              <li className={classForCharacterLengthText}>{validationRules.password.characterLength}</li>
              <li className={classForLetterAndCharacterText}>{validationRules.password.LetterNumber}</li>
              <li className={classForSpecialCharacterText}>{validationRules.password.specialCharacter}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fixed w-full bg-black/70 backdrop-blur-8 left-0 bottom-0 p-4 pb-5 z-10 select-none">
        <Button title={buttonTitle.continue} isDisabled={isContinueDisable} onClick={onClickContinueHandler} />
      </div>
    </OnboardingWrapper>
  );
};

export default withAuthGuard(ManagePassword);

import React, { useCallback, useMemo, useState } from "react";
import { useAuth } from "../../../../store/contextAPI/authContext";
import { validatePassword } from "../../../../utils/common-function";

const useCreatePassword = () => {
  const { setPasswordContext, handleUpdatePassword } = useAuth();
  const [password, setPassword] = useState({
    value: "",
    error: {
      isValidLength: false,
      hasLetterAndNumber: false,
      hasSpecialCharacters: false
    }
  });

  const isContinueDisable = useMemo((): boolean => {
    const error = validatePassword(password.value);
    return !(error.isValidLength && error.hasLetterAndNumber && error.hasSpecialCharacters);
  }, [password.value]);

  const onChangePassword = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let { value } = event.target;
      value = value.replace(/\s+/g, "");
      if (value.length > 20) {
        value = value.slice(0, 20);
      }
      const errorMessageFlages = validatePassword(value);
      setPassword({ value, error: errorMessageFlages });
    },
    [validatePassword]
  );

  const onClickContinueHandler = useCallback(async () => {
    setPasswordContext(password.value);
    handleUpdatePassword(password.value);
  }, [password.value, setPasswordContext]);

  const classForText = useCallback((value: string, error: boolean): string => {
    if (value.length === 0) return "";
    return error ? "" : "text-error";
  }, []);

  return {
    password,
    classForText,
    onClickContinueHandler,
    onChangePassword,
    isContinueDisable
  };
};

export default useCreatePassword;

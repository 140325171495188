import { gql } from '@apollo/client';

export const SAMPLE_ELIGIBLE_BRANDS = gql`
  query sampleEligibleBrands($campaignId: ID!, $userIdentifier: ID) {
    sampleEligibleBrands(
      campaignId: $campaignId
      userIdentifier: $userIdentifier
    ) {
      topEligibleBrands {
        id
        name
        imageUrl
      }
      brand {
        id
        name
        imageUrl
        videoUrl
        heroImageUrl
        username
        description
        brandColor
        registeredName
        location
        registeredNonProfit
        yearFounded
        numEmployees
        coinsGiven
        numSupporters
        numCampaigns
        numCauses
        website
        description
        heroTagline
        instagramUsername
        twitterUsername
        facebookUsername
        youtubeUsername
        tiktokUsername
        linkedinUrl
        instagramData
        hiringUrl
        shopUrl
        joinedAt
        brandImages {
          id
          imageUrl
        }
      }
      campaign {
        id
        title
        description
        location
        imageUrl
        currentAmount
        goalAmount
        endTime
        slug
        cause {
          id
          name
        }
        charity {
          id
          name
          imageUrl
          charityColor
        }
        associatedReferral {
          id
          referrer {
            id
            fullName
            username
            profilePhoto
          }
        }
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const SHARE_STASHLINK = gql`
  mutation shareStashlink(
    $stashlinkId: String
  ) {
    shareStashlink(
      input: {
        stashlinkId: $stashlinkId
      }
    ) {
      success
      error
    }
  }
`;

import InputField from "../../../components/common/input/input";
import Button from "../../../components/common/button";
import { buttonTitle, enterYourNameAttributes, heading } from "./constant";
import useEnterYourName from "./hooks/useEnterYourName";
import OnboardingWrapper from "../../../containers/onboardingWrapper";
import { routes } from "../../../routes/routes.constant";

const EnterYourName = () => {
  const {
    firstName,
    lastName,
    error,
    onChangeFirstName,
    onChangeLastName,
    isContinueDisable,
    onClickContinueHandler
  } = useEnterYourName();
  return (
    <OnboardingWrapper heading={heading.enterYourName} isTabVisible={true} tabId={0} totalTab={4} backButtonPath={routes.callback}>
      <div className="w-full p-4">
        <div className="w-full">
          <InputField
            label={enterYourNameAttributes.label.firstName}
            onChange={onChangeFirstName}
            inputClassName={enterYourNameAttributes.className}
            name={enterYourNameAttributes.name.firstName}
            placeholder={enterYourNameAttributes.placeHolder.firstName}
            value={firstName}
            showClearIcon={firstName.length !== 0}
          />
          <InputField
            label={enterYourNameAttributes.label.lastName}
            onChange={onChangeLastName}
            inputClassName={enterYourNameAttributes.className}
            name={enterYourNameAttributes.name.lastName}
            placeholder={enterYourNameAttributes.placeHolder.lastName}
            value={lastName}
            showClearIcon={lastName.length !== 0}
          />
        </div>
      </div>
      {error ? <p className="text-xs font-medium text-error mx-0.5 font-secondary text-left">{error}</p> : <span></span>}
      <div className="fixed w-full bg-black/70 backdrop-blur-8 left-0 bottom-0 p-4 pb-5 z-10 select-none">
        <Button title={buttonTitle.continue} isDisabled={isContinueDisable} onClick={onClickContinueHandler} />
      </div>
    </OnboardingWrapper>
  );
};

export default EnterYourName;

import MainHeader from "../../containers/layout/header/header";
import ErrorScreen from "./errorScreen";

const NotFound = () => (
  <div>
    <MainHeader />
    <ErrorScreen />
  </div>
);

export default NotFound;

import { ssoButtonDetails } from "../../modules/Accounts/Onboarding/constant";
import { icons, ISSOButton } from "./type";

const SSOButton = (props: ISSOButton) => {
  const { logo,onClickHandler } = props;
  const Logo = icons[logo];
  const title = ssoButtonDetails[logo].title;
  const color = ssoButtonDetails[logo].color;

  return (
    <button
      className="w-full px-4 py-3 text-sm font-bold font-primary bg-grey/5 border border-grey text-base-white rounded-lg mb-3 flex items-center justify-center relative"
      onClick={onClickHandler}>
      <Logo className="absolute left-4 w-7 h-7" color={color} />
      <span className="mx-auto">{title}</span>
    </button>
  );
};

export default SSOButton;

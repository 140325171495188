import React from "react";
import { SocialMediaButtonsProps } from "./social-media-buttons.types";
import FaceBookIcon from "../../svgImages/faceBook";
import { getColor, getStyles } from "../../../utils/common-function";
import InstaGramIcon from "../../svgImages/instaGram";
import TwitterIcon from "../../svgImages/twitter";
import YouTubeIcon from "../../svgImages/youTube";
import TikTokIcon from "../../svgImages/tiktok";
import LinkedInIcon from "../../svgImages/linkedIn";
import mixpanel from "../../../services/mixpanelService";

const SocialMediaButtons: React.FC<SocialMediaButtonsProps> = ({
  facebookUsername,
  twitterUsername,
  instagramUsername,
  youtubeUsername,
  tiktokUsername,
  linkedinUrl,
  impactScoreLevel,
  screen,
  useLightColorIcon
}) => {
  const socialUsernameMap: { [key: string]: string } = {
    ...(!!facebookUsername?.length && { facebook: facebookUsername }),
    ...(!!twitterUsername?.length && { twitter: twitterUsername }),
    ...(!!instagramUsername?.length && { instagram: instagramUsername }),
    ...(!!youtubeUsername?.length && { youtube: youtubeUsername }),
    ...(!!tiktokUsername?.length && { tiktok: tiktokUsername }),
    ...(!!linkedinUrl?.length && { linkedin: linkedinUrl })
  };

  const socialNetworks = Object.keys(socialUsernameMap);
  const lightColorStyles = getColor(impactScoreLevel);
  const primaryColorStyles = getStyles(impactScoreLevel);

  const iconMap: { [key: string]: JSX.Element } =
    useLightColorIcon === true
      ? {
          facebook: <FaceBookIcon color={lightColorStyles.levelLightColor} className="w-[30px]" />,
          twitter: <TwitterIcon color={lightColorStyles.levelLightColor} className="w-[30px]" />,
          instagram: <InstaGramIcon color={lightColorStyles.levelLightColor} className="w-[30px]" />,
          youtube: <YouTubeIcon color={lightColorStyles.levelLightColor} className="w-[30px]" />,
          tiktok: <TikTokIcon color={lightColorStyles.levelLightColor} className="w-[30px]" />,
          linkedin: <LinkedInIcon color={lightColorStyles.levelLightColor} className="w-[30px]" />
        }
      : useLightColorIcon === false
        ? {
            facebook: <FaceBookIcon color={primaryColorStyles.levelColor} className="w-[30px]" />,
            twitter: <TwitterIcon color={primaryColorStyles.levelColor} className="w-[30px]" />,
            instagram: <InstaGramIcon color={primaryColorStyles.levelColor} className="w-[30px]" />,
            youtube: <YouTubeIcon color={primaryColorStyles.levelColor} className="w-[30px]" />,
            tiktok: <TikTokIcon color={primaryColorStyles.levelColor} className="w-[30px]" />,
            linkedin: <LinkedInIcon color={primaryColorStyles.levelColor} className="w-[30px]" />
          }
        : {
            facebook: <FaceBookIcon className="w-[30px] opacity-80 " />,
            twitter: <TwitterIcon className="w-[30px] opacity-80" />,
            instagram: <InstaGramIcon className="w-[30px] opacity-80" />,
            youtube: <YouTubeIcon className="w-[30px] opacity-80" />,
            tiktok: <TikTokIcon className="w-[30px] opacity-80" />,
            linkedin: <LinkedInIcon className="w-[30px] opacity-80" />
          };

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>, socialNetwork: string) => {
    const event = `${screen}-${socialNetwork}-clicked`;
    mixpanel.track(event);
  };

  return (
    <div className="flex flex-row gap-4">
      {socialNetworks.map((socialNetwork) => {
        const url =
          socialNetwork === "linkedin"
            ? socialUsernameMap[socialNetwork]
            : `https://${socialNetwork}.com/${socialUsernameMap[socialNetwork]}`;

        return (
          <a
            onClick={(e) => handleClick(e, socialNetwork)}
            key={socialNetwork}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-gray-900 cursor-pointer">
            {iconMap[socialNetwork]}
          </a>
        );
      })}
    </div>
  );
};

export default SocialMediaButtons;

import { gql } from "@apollo/client";

export const SYNC_SUPABASE_USER = gql`
  mutation SyncSupabaseUser($input: SyncSupabaseUserInput!) {
    syncSupabaseUser(input: $input) {
      user {
        id
      }
      errors
    }
  }
`;

import { useCallback, useEffect, useState } from "react";
import { getLocalStorage, setLocalStorage } from "../../utils/common-function";
import { getSupabaseSession } from "./../../utils/common-function";
import { IProfileMenu } from "./types";

const useProfileMenu = (props: IProfileMenu) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
  const [provider, setProvider] = useState<string | null>(null); 
  const [isEmailProvider, setIsEmailProvider] = useState<boolean>(false);

  const getProvider = useCallback(async (): Promise<string | null> => {
    const session = await getSupabaseSession();
    return session?.user.app_metadata?.provider ?? null;
  }, []);

  useEffect(() => {
    const fetchProvider = async () => {
      const providerResult = await getProvider();
      setProvider(providerResult); 
    };

    fetchProvider();
  }, [getProvider]);

  useEffect(() => {
    if (provider !== null) {
      setIsEmailProvider(provider === "email");
    }
  }, [provider]);

  const handleLogoutClick = useCallback(() => {
    setIsModalOpen(true);
  }, [isEmailProvider]);

  const onConfirm = useCallback(() => {
    setIsModalOpen(false);
    setLocalStorage("impactScoreLevel", "");
    props.onClose();
  }, [props]);

  useEffect(() => {
    const token = getLocalStorage("token");
    if (!token) {
      setIsUserLoggedIn(false);
    } else {
      setIsUserLoggedIn(true);
    }
  }, []);

  return {
    isUserLoggedIn,
    isModalOpen,
    onConfirm,
    handleLogoutClick,
    setIsModalOpen,
    isEmailProvider
  };
};

export default useProfileMenu;

import InputField from "../common/input/input";
import ErrorText from "../errorText";
import { ProfileInputProps } from "./types";

const ProfileInput: React.FC<ProfileInputProps> = ({ value, onChange, label, name, placeholder, error, errorMessage, showClearIcon }) => (
  <>
    <InputField
      value={value}
      onChange={onChange}
      label={label}
      name={name}
      placeholder={placeholder}
      showClearIcon={showClearIcon}
      inputClassName="!bg-grey/5 !text-neutral-50 !placeholder:grey/50"
      error={errorMessage.length>0?error:""}
    />
    {value.length === 0 && <ErrorText text={errorMessage} />}
  </>
);

export default ProfileInput;

import * as React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getLocalStorage } from "../utils/common-function";
import { routes } from "./routes.constant";
import useUser from "../services/hooks/useUser";

// Make route paths accessible by single layer map of keys to avoid hard coding routes in links
export const generateRouteMap = (routes: any) => {
  let pathMap: any = {},
    configMap: any = {};
  for (const route of routes) {
    pathMap[route.key] = route.path;
    configMap[route.key] = route;
    if (route.routes) {
      const { pathMap: nestedPaths, configMap: nestedConfigMap } = generateRouteMap(route.routes);
      pathMap = { ...pathMap, ...nestedPaths };
      configMap = { ...configMap, ...nestedConfigMap };
    }
  }

  return { pathMap, configMap };
};

export const withAuthGuard = (WrappedComponent: React.FC) => () => {
  const history = useHistory();

  React.useEffect(() => {
    const token = getLocalStorage("token");
    if (token) {
      history.push("/");
    }
  }, [history]);

  return <WrappedComponent />;
};

// Display Routes
export const displayRouteMenu = (routes: any) => {
  /**
   * Render a single route as a list item link to the config's pathname
   */
  const singleRoute = (route: any) => (
    <li key={route.key}>
      <Link to={route.path}>
        {route.title} ({route.path})
      </Link>
    </li>
  );

  // loop through the array of routes and generate an unordered list
  return (
    <ul>
      {routes.map((route: any) => {
        // if this route has sub-routes, then show the ROOT as a list item and recursively render a nested list of route links
        if (route.routes) {
          return (
            <React.Fragment key={route.key}>
              {singleRoute(route)}
              {displayRouteMenu(route.routes)}
            </React.Fragment>
          );
        }

        // no nested routes, so just render a single route
        return singleRoute(route);
      })}
    </ul>
  );
};

export const ProtectedRoute = (WrappedComponent: React.FC) => () => {
  const history = useHistory();
  const location = useLocation();
  const userId = getLocalStorage("userId");
  const { userData } = useUser(userId);

  React.useEffect(() => {
    const token = getLocalStorage("token");
    if (token) {
      if (userData) {
        const { finishedOnBoarding } = userData;
        if (!finishedOnBoarding) {
          history.push(routes.callback, { state: location.state, from: location.pathname });
        }
      }
    }
  }, [history, userData]);

  return <WrappedComponent />;
};

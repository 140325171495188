import mixpanel from "mixpanel-browser";
import { MIXPANEL_TOKEN } from "../common/constants";

mixpanel.init(MIXPANEL_TOKEN, {
  debug: process.env.REACT_APP_ENABLE_DEV_MODE === "true",
  // eslint-disable-next-line camelcase
  track_pageview: "full-url"
});

export default mixpanel;

import { Link } from "react-router-dom";
import InputField from "../../../components/common/input/input";
import SSOButton from "../../../components/ssoButton";
import Button from "../../../components/common/button";
import useEnterEmail from "./hooks/useEnterEmail";
import { subHeading, emailAttributes, buttonTitle, stashruns, heading } from "./constant";
import { withAuthGuard } from "../../../routes/helpers";
import { generateProfileImageUrl, getStyles } from "../../../utils/common-function";
import OnboardingWrapper from "../../../containers/onboardingWrapper";

const EnterEmail = () => {
  const {
    user,
    email,
    onChangeEmail,
    isContinueDisable,
    onClickContinueHandler,
    onClickGoogleHandler,
    followerName,
    followerProfileImage,
    backButtonPath,
    showErrorMessage
  } = useEnterEmail();
  

  const styles = getStyles();

  return (
    <OnboardingWrapper heading={heading.loginSignUpPage} backButtonPath={backButtonPath}>
      <div>
        <p className="text-sm text-base-white/80 leading-tight-18.2 mx-6 text-center font-normal font-secondary">
          {subHeading.loginOrSignup}
        </p>
      </div>
      {followerName ? (
        <div
          className="w-full py-2 px-6 flex items-center mt-3 mb-1"
          style={{
            background: `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), linear-gradient(to top, ${styles.levelColor} 0%, ${styles.levelColor} 100%)`
          }}>
          <div className="min-w-16 min-h-66px max-w-16 max-h-[66px] relative rounded-full" style={{ background: styles.levelColor }}>
            <div
              className="w-[58px] h-[58px] rounded-full  absolute left-2/4 -translate-x-2/4 bottom-[1px]"
              style={{
                backgroundImage: `url(${generateProfileImageUrl(followerProfileImage, "116", "116")})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat"
              }}></div>
          </div>
          <div className="ml-4 text-sm text-base-white">
            <p className="font-secondary text-base-white font-normal leading-tight-18.2">
              Be the first to know when{" "}
              <span className="font-bold" style={{ color: styles.levelColor }}>
                {followerName}’s
              </span>{" "}
              next play to fund campaign goes live.
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="w-full p-4">
        <div className="w-full hello">
          <InputField
            label={emailAttributes.label}
            onChange={onChangeEmail}
            inputClassName={emailAttributes.className}
            name={emailAttributes.name}
            placeholder={emailAttributes.placeHolder}
            value={email.value}
            error={showErrorMessage}
            showClearIcon={email.value.length !== 0}
          />
          {showErrorMessage ? <p className="text-xs text-error -mt-10 mx-2 mb-4">{email.error}</p> : <></>}
          <Button title={buttonTitle.continue} isDisabled={isContinueDisable} onClick={onClickContinueHandler} />
        </div>
        {user.isSSOError ? <div className="text-center text-error mt-2">Something went wrong with SSO, please try again</div> : <></>}
        <div className="text-center text-grey mt-4">OR</div>
        <div className="pt-6 pb-2">
          <SSOButton logo="google" onClickHandler={onClickGoogleHandler} />
          {/* TODO: Disbaled the meta login as current backend does not support */}
          {/* <SSOButton logo="meta" onClickHandler={onClickMetaHandler} /> */}
          {/* TODO: Disbaled the Apple login as current backend doesnot support, autentication by phone */}
          {/* <SSOButton logo="apple" onClickHandler={onClickAppleHandler} /> */}
        </div>
        <div className="text-grey text-xs text-center font-secondary">
          <p>
            By continuing, you agree to {stashruns}{" "}
            <u>
              <Link target="_blank" to="/terms" className="hover:underline">
                <strong className="mr-0.5">Terms of Service</strong>
              </Link>
            </u>
            and confirm that you have read {stashruns}
            <u>
              <Link target="_blank" to="/privacy" className="hover:underline">
                <strong className="mr-0.5">Privacy Policy</strong>
              </Link>
            </u>{" "}
            to learn how we collect, use, and share your data.
          </p>
        </div>
      </div>
    </OnboardingWrapper>
  );
};

export default withAuthGuard(EnterEmail);

import React, { useState, useEffect } from "react";
// import { useFeatureIsOn } from "@growthbook/growthbook-react";
// import Button from "../../components/common/button";
import Loader from "../../components/common/loader/loader";
import useThankYouLogic from "./hooks/useThankYou";
import ShareComponent from "../../components/shareModal/shareModal";
import VolumeMute from "./../../assets/images/Mute.svg";
import VolumeFull from "./../../assets/images/volume-full.svg";
// import useColinkExist from "../../services/hooks/useColinkExist";
// import FollowingCheck from "../../assets/images/following-check.svg";
import ShareIcon from "./../../assets/images/shareIconBlackFill.svg";
import {
  generateCloudinaryImageUrl,
  generateCloudinaryVideoThumbnail,
  generateCloudinaryVideoUrl,
  generateProfileImageUrl,
  getLocalStorage
} from "../../utils/common-function";
import mixpanel from "../../services/mixpanelService";
import WaitlistModal from "../../components/waitlistModal";
import { envVariables } from "../../common/constants";
import useWaitlist from "./hooks/useWaitlist";
import { SampleEligibleBrandsData } from "../../types";
import ContinueModal from "../../components/continueModal";

const ThankYou: React.FC = () => {
  const {
    videoRef,
    stashlink,
    navigateToColink,
    navigateToStashAgain,
    parentVideoRef,
    userData,
    campaign,
    showParentVideo,
    parentVideoProgress,
    videoProgress,
    muted,
    setMuted,
    isShareModalOpen,
    setIsShareModalOpen,
    isContinueModalOpen,
    setIsContinueModalOpen,
    changeParentTab
  } = useThankYouLogic();
  // const { colinkExistData } = useColinkExist(stashlink?.id || "");
  const [isLoading, setIsLoading] = useState(true);
  const [isColinkVideoLoading, setIsColinkVideoLoading] = useState(true);
  const [dynamicImageSize, setDynamicImageSize] = useState({ width: "", height: "" });

  // const onboardingFeatureFlag = useFeatureIsOn("onboarding-flow");

  // const userId = getLocalStorage("userId") as string;
  const coinDropId = getLocalStorage("coinDropId");
  const brandData = getLocalStorage("brandsData") as SampleEligibleBrandsData;

  const colinkVideoUrl = stashlink.thankYouMessageVideo?.replace(".mkv", ".mp4") ?? "";
  const parentVideoUrl = stashlink.parentStashlink?.thankYouMessageVideo?.replace(".mkv", ".mp4") ?? "";
  const colinkVideoThumbnail =
    stashlink.thankYouMessageVideo && generateCloudinaryVideoThumbnail(stashlink.thankYouMessageVideo, "750", "930", 1); // Example for generating thumbnail
  const parentVideoThumbnail =
    stashlink.parentStashlink?.thankYouMessageVideo &&
    generateCloudinaryVideoThumbnail(stashlink.parentStashlink?.thankYouMessageVideo, "750", "930", 1);
  const shareUrl = `${envVariables.domainUrl}/stashlink/${stashlink.id}`;
  const { isWaitlistModalOpen, setWaitlistModalOpen } = useWaitlist();

  const closeWaitListModal = () => {
    setWaitlistModalOpen(false);
  };

  const closeContinueModal = () => {
    setIsContinueModalOpen(false);
  };

  const hasNextOrPreviousMedia = () => {
    const hasParentMedia = !!stashlink.parentStashlink?.id;
    return hasParentMedia;
  };

  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      if (windowHeight < 720) {
        setDynamicImageSize({ width: "560", height: "560" });
      } else {
        setDynamicImageSize({ width: "800", height: "1200" });
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return !stashlink ? (
    <div className="h-[calc(100vh-80px)] w-screen flex justify-center items-center bg-soft-black">
      <Loader />
    </div>
  ) : (
    <>
      <div className="bg-grey-bg text-sm w-full h-7 text-white flex items-center justify-center">
        <a className="no-underline" href="https://www.stashrun.com/donation-disclosures">
          🎉 <b>$1.00 donated!</b>&nbsp;Receipt #{coinDropId}
        </a>
      </div>
      <div className="relative h-[calc(100vh-96px)] w-screen flex flex-col items-center pt-7 overflow-hidden">
        <div className="w-full h-24 absolute z-10 top-0 bg-gradient-to-b from-soft-black/70 from-30% via-soft-black/60 via-60% to-soft-black/1 to-100%"></div>
        <div className={`absolute top-2 w-[calc(100vw-32px)] ${stashlink.parentStashlink && "grid grid-cols-2 gap-2"}`}>
          {stashlink.parentStashlink && (
            <div className="relative bg-grey w-full h-0.5 rounded-xl z-10">
              <div className="bg-base-white absolute left-0 h-0.5 thank-you-progress-bar" style={{ width: `${parentVideoProgress}%` }}></div>
            </div>
          )}
          <div className="relative bg-grey w-full h-0.5 rounded-xl z-10">
            <div className="bg-base-white absolute left-0 h-0.5 thank-you-progress-bar" style={{ width: `${videoProgress}%` }}></div>
          </div>
        </div>

        <div className="absolute top-7 left-4 w-9 h-c-38 bg-primary-l1 rounded-full z-10">
          <img
            src={
              showParentVideo
                ? generateProfileImageUrl(stashlink.parentStashlink?.user.profilePhoto || "", "65", "65")
                : generateProfileImageUrl(stashlink.user.profilePhoto || "", "65", "65")
            }
            alt={showParentVideo ? stashlink.parentStashlink?.user.fullName : stashlink.user.fullName}
            className="w-33 h-33 absolute left-2/4 -translate-x-2/4 bottom-px rounded-full object-cover"
          />
          {/* <div className="p-[2px] absolute left-8 w-9 h-9 rounded-full bg-soft-black flex justify-center items-center"> */}
            <img
              src={generateCloudinaryImageUrl(brandData.sampleEligibleBrands?.campaign.charity?.imageUrl || "", "65", "65")}
              alt="charity logo"
              className="absolute left-8 h-c-38 w-c-38 object-cover rounded-full border-2 border-soft-black"
            />
          {/* </div> */}

          <div className="absolute -top-1 left-20 w-screen font-[750] text-base text-neutral-50 [text-shadow:_2px_2px_0_rgb(0_0_0_/_30%)]">
            <b className="font-primary">{showParentVideo ? stashlink.parentStashlink?.user.fullName : stashlink.user.fullName}</b>
            <div className="text-sm text-neutral-50 font-normal [text-shadow:_2px_2px_0_rgb(0_0_0_/_60%)]">
              for <b className="font-semibold [text-shadow:_2px_2px_0_rgb(0_0_0_/_60%)]">{brandData.sampleEligibleBrands?.campaign.charity?.name}</b>
            </div>
          </div>
        </div>

        {((!showParentVideo && stashlink.thankYouMessageVideo) || (showParentVideo && stashlink.parentStashlink?.thankYouMessageVideo)) && (
          <div className="absolute top-8 right-4 bg-transparent/10 rounded-full z-10 h-8 w-8 flex items-center justify-center backdrop-filter-blur">
            <img
              src={muted ? VolumeMute : VolumeFull}
              alt={VolumeFull}
              onClick={(event) => {
                mixpanel.track("sound-clicked", {
                  location: "thank-you-page",
                  muted: !muted,
                  campaignName: campaign?.title,
                  stashlinkId: stashlink?.id
                });
                event.stopPropagation();
                setMuted(!muted);
              }}
            />
          </div>
        )}

        {showParentVideo &&
          (stashlink.parentStashlink?.thankYouMessageVideo && showParentVideo ? (
            <div className="relative w-full h-full">
              <div className="absolute top-0 left-0 w-full h-full z-20">
                <div onClick={() => changeParentTab(false)} className="absolute top-0 right-0 w-1/2 h-full cursor-pointer"></div>
              </div>
              {isColinkVideoLoading && (
                <div className="absolute inset-0 flex items-center justify-center z-10">
                  <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
                </div>
              )}
              <video
                muted={muted}
                ref={parentVideoRef}
                poster={parentVideoThumbnail}
                src={generateCloudinaryVideoUrl(parentVideoUrl, "750", "930")}
                className={`object-cover w-full h-full ${isColinkVideoLoading ? "opacity-0" : "opacity-100"} transition-opacity duration-500`}
                playsInline
                autoPlay
                onLoadedData={() => setIsColinkVideoLoading(false)}
                onEnded={() => changeParentTab(false)}
              />
              <div
                //  onClick={() => {
                //     if (parentVideoRef.current) {
                //       // if video is already at end, start at beginning again
                //       if (parentVideoRef.current.currentTime === parentVideoRef.current.duration) {
                //         parentVideoRef.current.currentTime = 0;
                //       }

                //       if (parentVideoRef.current.paused) {
                //         parentVideoRef.current.play();
                //       } else {
                //         parentVideoRef.current.pause();
                //       }
                //     }
                //   }}
                className="absolute w-full h-full top-0 left-0 bg-gradient-to-t from-soft-black/0 from-30% via-soft-black/0 via-90% to-soft-black/60 to-100%"></div>
            </div>
          ) : (
            <div className="absolute top-0 left-0 w-full h-full">
              <div>
                <img
                  src={generateCloudinaryImageUrl(stashlink.parentStashlink?.user.profilePhoto || "", "750", "930")}
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full object-cover h-full w-full"
                />
              </div>
              <div onClick={() => changeParentTab(false)} className="absolute top-0 right-0 w-1/2 h-full cursor-pointer"></div>
            </div>
          ))}

        {!showParentVideo &&
          (stashlink.thankYouMessageVideo ? (
            <div className="absolute top-0 right-0 w-full h-full overflow-hidden">
              {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center z-10">
                  <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
                </div>
              )}
              <video
                muted={muted}
                ref={videoRef}
                src={generateCloudinaryVideoUrl(colinkVideoUrl, "750", "930")}
                className={`object-cover w-full h-full ${isLoading ? "opacity-0" : "opacity-100"} transition-opacity duration-500`}
                autoPlay
                poster={colinkVideoThumbnail}
                playsInline
                onLoadedData={() => setIsLoading(false)}
                onEnded={() => {
                  // changeParentTab(!!stashlink?.parentStashlink)
                  setIsContinueModalOpen(true);
                }}
              />
              <div
                // onClick={() => {
                //   if (videoRef.current) {
                //     // if video is already at end, start at beginning again
                //     if (videoRef.current.currentTime === videoRef.current.duration) {
                //       videoRef.current.currentTime = 0;
                //     }

                //     if (videoRef.current.paused) {
                //       videoRef.current.play();
                //     } else {
                //       videoRef.current.pause();
                //     }
                //   }
                // }}
                className="absolute w-full h-full top-0 left-0 bg-gradient-to-t from-soft-black/0 from-30% via-soft-black/0 via-90% to-soft-black/60 to-100%"></div>
              {hasNextOrPreviousMedia() && (
                <div onClick={() => changeParentTab(true)} className="absolute top-0 left-0 w-1/2 h-full cursor-pointer"></div>
              )}
            </div>
          ) : (
            <div className="absolute top-0 left-0 w-full h-full">
              <div className="absolute top-0 right-0 w-full h-full"></div>
              <div className="h-full w-full">
                <img
                  src={generateCloudinaryImageUrl(stashlink.user.profilePhoto ?? "", dynamicImageSize.width, dynamicImageSize.height)}
                  className="h-full w-full object-cover"
                />
                {hasNextOrPreviousMedia() && (
                  <div onClick={() => changeParentTab(true)} className="absolute top-0 left-0 w-1/2 h-full cursor-pointer"></div>
                )}
              </div>
            </div>
          ))}

        <div className="w-screen absolute bottom-0">
          <div className="fixed bottom-0 w-full flex flex-col z-10 items-center justify-center bg-gradient-to-t from-soft-black from-50% via-soft-black/70 via-70% to-soft-black/1 to-100% pt-8">
            {showParentVideo &&
              (stashlink.parentStashlink?.thankYouMessageVideo && showParentVideo ? (
                <div></div>
              ) : (
                <div className="relative top-0 left-0 w-full h-full">
                  <div className="w-full">
                    <p className="bg-gradient-to-t from-soft-black from-40% via-soft-black/70 via-70% to-base-white/0 to-90% text-white text-base leading-tight-19 text-center w-full p-4 px-5 pb-5 pt-16 [text-shadow:_1px_1px_0_rgb(0_0_0_/_60%)] inter-I">
                      {stashlink.parentStashlink?.thankYouMessage}
                    </p>
                  </div>
                </div>
              ))}

            {!showParentVideo &&
              (stashlink.thankYouMessageVideo ? (
                <div></div>
              ) : (
                <div className="relative w-full h-full">
                  <div className="w-full">
                    <p className="bg-gradient-to-t from-soft-black from-40% via-soft-black/70 via-70% to-base-white/0 to-90% text-white text-base leading-tight-19 text-center w-full p-4 px-5 pb-5 pt-16 [text-shadow:_1px_1px_0_rgb(0_0_0_/_60%)] inter-I">
                      {stashlink?.thankYouMessage}
                    </p>
                  </div>
                </div>
              ))}

            {/* {onboardingFeatureFlag && (
              <div className="w-full flex gap-3 mb-2">
                <div className="w-full px-12">
                  {colinkExistData || stashlink.user.id === userId ? (
                    <div className="w-full mt-2 p-4">
                      <button
                        onClick={() => mixpanel.track("team-up-button-clicked")}
                        className="w-full px-4 py-3 text-base font-primary tracking-wider font-wght-740 rounded-lg text-grey bg-grey-bg border border-grey flex align-center justify-center">
                        Teamed Up{" "}
                        <span className="ml-1">
                          <img src={FollowingCheck} alt="Following Check"></img>
                        </span>
                      </button>
                    </div>
                  ) : (
                    <Button isDisabled={false} title="Team up" onClick={navigateToColink} />
                  )}
                </div>
              </div>
            )} */}
            <div className="w-full flex flex-col gap-3 items-center px-4">
              <button
                onClick={() => {
                  mixpanel.track("share-button-clicked", {
                    campaignName: campaign?.title,
                    charityName: campaign?.charity?.name,
                    stashlinkId: stashlink?.id
                  });
                  setIsShareModalOpen(true);
                }}
                className="w-full flex items-center justify-center p-3 min-w-[283px] mb-1 bg-primary-l1 rounded-lg h-11 border-2 border-black tracking-wide leading-snug text-black text-center text-lg font-primary font-wght-750 shadow-[0px_4px_0px_#6C801F] select-none">
                Share
                <img className="text-black h-[26px] w-[26px] ml-1" src={ShareIcon} alt={ShareIcon} />
              </button>
              <button
                onClick={() => {
                  mixpanel.track("continue-button-clicked-thank-you", {
                    campaignName: campaign?.title,
                    charityName: campaign?.charity?.name,
                    stashlinkId: stashlink?.id
                  });
                  setIsContinueModalOpen(true);
                }}
                className="w-full flex items-center justify-center p-3 min-w-[283px] mb-5 bg-black-op10 rounded-lg h-11 border border-grey-op70 text-neutral-50 tracking-wide text-center text-sm font-primary font-wght-740 leading-tight-16.8 select-none">
                Continue
              </button>
            </div>
          </div>
        </div>
        {isShareModalOpen && (
          <ShareComponent
            stashUser={stashlink.parentStashlink?.user || userData}
            coLinkUser={stashlink.parentStashlink?.user ? userData : undefined}
            charityName={campaign.charity?.name}
            charityLogo={campaign.charity?.imageUrl}
            currentAmount={campaign.currentAmount + 1}
            goalAmount={campaign.goalAmount}
            shareUrl={shareUrl}
            isOpen={isShareModalOpen}
            onClose={() => setIsShareModalOpen(false)}
          />
        )}
        <ContinueModal
          isOpen={isContinueModalOpen}
          onClose={closeContinueModal}
          campaign={campaign}
          openShareModal={() => setIsShareModalOpen(true)}
          navigateToStashAgain={navigateToStashAgain}
          navigateToColink={navigateToColink}
        />
        <WaitlistModal stashUser={stashlink.parentStashlink?.user || userData} isOpen={isWaitlistModalOpen} onClose={closeWaitListModal} />
      </div>
    </>
  );
};

export default ThankYou;

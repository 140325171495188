import React, { useEffect, useState } from "react";
import { CountDownTimerProps } from "./countDownTimer.types";
import { timeUnits } from "../../../utils/constants";
import dayjs from "dayjs";
import duration, { Duration } from "dayjs/plugin/duration";
import { convertTimeLeftInUnits, getFormattedDate } from "../../../utils/common-function";

dayjs.extend(duration);

const CountDownTimer: React.FC<CountDownTimerProps> = (props: CountDownTimerProps) => {
  const { days, hours, minutes, seconds } = getFormattedDate(props.endTime);
  const [timeLeft, setTimeLeft] = useState<Duration>(
    dayjs.duration({
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds
    })
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime.asSeconds() <= 1) {
          clearInterval(timer);
          return dayjs.duration(0);
        }
        return prevTime.subtract(1, "second");
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const isTimeAlmostOver = convertTimeLeftInUnits(timeLeft, props.unit) <= props.alertTimer;

  return (
    <div className="text-center w-full bg-modal-bottom-bg py-2">
      <p className="text-lg font-semibold">
        {timeUnits.map((timeUnit, index) => (
          <span key={index} className="inline-block me-1.5 bg-black/70 rounded-lg p-1 font-normal font-secondary text-sm">
            <span className={`font-semibold ${isTimeAlmostOver ? "text-red-500" : ""}`}>
              {timeLeft[timeUnit.unit]()}
              {timeUnit.label}
            </span>
          </span>
        ))}
      </p>
    </div>
  );
};

export default CountDownTimer;

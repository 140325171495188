import { useCallback } from 'react';
import { useAuth } from "../../store/contextAPI/authContext";

export const useLogoutModal = (onConfirm: () => void, setIsModalOpen: (isOpen: boolean) => void) => {
  const { logout, handleUserLogout } = useAuth();

  const onClose = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const handleLogout = useCallback(async () => {
    onConfirm();
    await handleUserLogout();
    logout();
  }, [onConfirm, handleUserLogout, logout]);

  return { onClose, handleLogout };
};

class Scroll {
  lock() {
    document.body.style.overflow = "hidden";
  }

  enable() {
    document.body.style.overflow = "auto";
  }

  lockInput() {
    document.body.addEventListener("touchmove", this.preventTouch, {
      passive: false
    });
  }

  unlockInput() {
    document.body.removeEventListener("touchmove", this.preventTouch);
  }

  preventTouch(e: TouchEvent) {
    e.preventDefault();
  }
}

const scroll = new Scroll();
export default scroll;

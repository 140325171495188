import { UPDATE_USERS } from "../../actions";
import { IUserState } from "../../common/interfaces";

const obj1: any = {
  id: "11",
  name: "Clementina DuBuque",
  email: "Rey.Padberg@karina.biz"
};

// Initial object with data exists
const initialState: IUserState = {
  users: [obj1],
  user: obj1
};

// Optional: If no initial data exists, structure needs to be created.
// const initialState: IUserInit = {
//     users: [],
//     user: {}
// };

const users = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_USERS:
      return {
        ...state,
        users: [...state.users, ...action.payload]
      };
    default:
      return state;
  }
};

export default users;

import { useQuery, ApolloError } from "@apollo/client";
import { COLINK_EXIST } from "../../queries";

interface UseStashlinkExistResponse {
  hasColinkedBefore: boolean;
}

interface UseColinkExistHook {
  colinkExistLoading: boolean;
  colinkExistError?: ApolloError;
  colinkExistData?: boolean;
}

const useColinkExist = (stashlinkId: string): UseColinkExistHook => {
  const {
    loading: colinkExistLoading,
    error: colinkExistError,
    data
  } = useQuery<UseStashlinkExistResponse>(COLINK_EXIST, {
    variables: {
        stashlinkId
    },
    fetchPolicy: "network-only"
  });
  const colinkExistData = data?.hasColinkedBefore;
  return { colinkExistLoading, colinkExistError, colinkExistData };
};

export default useColinkExist;

import { Duration } from "dayjs/plugin/duration";
import { supabaseClient } from "../services/supabase/supabaseClient";
import { IMPACT_LEVEL_COLORS, IMPACT_LEVEL_LIGHT_COLORS, IMPACT_LEVELS } from "./constants";
import { toast } from "react-toastify";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { History, Location } from "history";
import { ILocationState } from "../components/profileMenu/types";

export const preloadImages = (arr: any[]) => {
  arr.forEach((picture) => {
    const img = new Image();
    img.src = picture;
  });
};

export const isEmailValid = (input: string): boolean => {
  const trimValue: string = input.trim();
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValid = re.test(String(trimValue).toLowerCase());
  return isValid;
};

export const validateName = (name: string): boolean => {
  const namePattern = /^[A-Za-z\s-]+$/;
  return name.trim().length !== 0 && namePattern.test(name) && name.length <= 35;
};

export const validatePassword = (input: string) => {
  const validLength = /^.{8,20}$/;
  const letterNumber = /^(?=.*[A-Za-z])(?=.*\d)/;
  const specialCharacters = /(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?`~])/;

  return {
    isValidLength: validLength.test(input),
    hasLetterAndNumber: letterNumber.test(input),
    hasSpecialCharacters: specialCharacters.test(input)
  };
};

export const setLocalStorage = (key: string, value: any): void => {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch (error) {
    console.error("Error saving to localStorage", error);
  }
};

export const getLocalStorage = (key: string) => {
  try {
    const serializedValue = localStorage.getItem(key);
    if (serializedValue === null) {
      return null;
    }
    return JSON.parse(serializedValue);
  } catch (error) {
    console.error("Error reading from localStorage", error);
    return null;
  }
};
const date = new Date();

export const convertToPercentage = (raisedAmount?: number, targetAmount?: number) => {
  if (!raisedAmount || !targetAmount || targetAmount === 0) {
    return 0; // Return 0 or any fallback value you prefer
  }
  return (raisedAmount / targetAmount) * 100;
};

export const formatNumberWithUnits = (number: number) => {
  if (number > 1000000000) return (number / 1000000000).toFixed(1) + "B";
  if (number > 1000000) return (number / 1000000).toFixed(1) + "M";
  if (number > 1000) return (number / 1000).toFixed(1) + "K";
  return number;
};

export const currentMonthName = date.toLocaleString("default", { month: "long" });

export const getFormattedDate = (date?: string) => {
  const targetDate: Date = new Date(date || "");
  const currentDate: Date = new Date();

  // Calculate the difference in milliseconds
  const difference: number = targetDate.getTime() - currentDate.getTime();

  // Convert the difference to days, hours, minutes, and seconds
  const days: number = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours: number = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes: number = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds: number = Math.floor((difference % (1000 * 60)) / 1000);
  return { days, hours, minutes, seconds };
};

export const convertTimeLeftInUnits = (timeLeft: Duration, unit?: string) => {
  switch (unit) {
    case "seconds":
      return timeLeft.asSeconds();
    case "minutes":
      return timeLeft.asMinutes();
    case "hours":
      return timeLeft.asHours();
    case "days":
      return timeLeft.asDays();
    default:
      return timeLeft.asHours(); // Default to hours if no unit is provided
  }
};

export const lightenColor = (hex: string, percent: number) => {
  // Ensure the hex code is valid
  hex = hex.replace(/^#/, "");
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((x) => x + x)
      .join("");
  }

  // Convert hex to RGB
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Calculate the lightened RGB values
  r = Math.min(255, Math.floor(r + (255 - r) * percent));
  g = Math.min(255, Math.floor(g + (255 - g) * percent));
  b = Math.min(255, Math.floor(b + (255 - b) * percent));

  // Convert RGB back to hex
  const toHex = (x: number) => x.toString(16).padStart(2, "0");
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

export const setCookie = (name: string, value: string) => {
  let expires = "";
  const date = new Date();
  date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
  expires = "; expires=" + date.toUTCString();
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getCookie = (name: string) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const deleteCookie = (name: string) => {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

export const getSupabaseSession = async () => {
  const {
    data: { session }
  } = await supabaseClient.auth.getSession();
  return session;
};

export const eraseCookie = (name: string) => {
  document.cookie = name + "=; Max-Age=-99999999;";
};

export const convertHttpToHttps = (url?: string) => {
  if (url && url.startsWith("http://")) {
    return url.replace("http://", "https://");
  }
  return url;
};

export const formatUrlWithProtocol = (url?: string): string => (url?.startsWith("http") ? url : `https://${url}`);

export const getImpactLevel = (impactScore: number): string | null => {
  const levelsArray = Object.entries(IMPACT_LEVELS) as [string, number][];

  const levelEntry = levelsArray.find(([, threshold], index) => {
    const nextThreshold = index < levelsArray.length - 1 ? levelsArray[index + 1][1] : Infinity;
    return impactScore >= threshold && impactScore < nextThreshold;
  });

  return levelEntry ? levelEntry[0] : null;
};

export const getStyles = (impactScoreLevel?: string | null) => {
  const dynamicTheme = useFeatureIsOn("dynamic-theme");
  // Default color
  let levelColor = IMPACT_LEVEL_COLORS["1"];

  if (impactScoreLevel && dynamicTheme) {
    levelColor = IMPACT_LEVEL_COLORS[impactScoreLevel] || levelColor;
  }

  return {
    levelColor
  };
};

export const getColor = (impactScoreLevel?: string | null) => {
  const dynamicTheme = useFeatureIsOn("dynamic-theme");
  // Default color
  let levelLightColor = IMPACT_LEVEL_LIGHT_COLORS["1"];

  if (impactScoreLevel && dynamicTheme) {
    levelLightColor = IMPACT_LEVEL_LIGHT_COLORS[impactScoreLevel] || levelLightColor;
  }

  return {
    levelLightColor
  };
};

export const generateCloudinaryImageUrl = (url: string, width: string, height: string): string => {
  const splitUrl = url.split("/upload/");

  if (splitUrl.length !== 2) {
    console.error("Invalid Cloudinary URL");
    return url;
  }

  const newUrl = `${splitUrl[0]}/upload/h_${height},w_${width},c_auto/${splitUrl[1]}`;

  return newUrl;
};

export const generateCloudinaryVideoUrl = (url: string, width: string, height: string, cropString = "c_fit"): string => {
  const splitUrl = url.split("/upload/");

  if (splitUrl.length !== 2) {
    console.error("Invalid Cloudinary URL");
    return url;
  }

  const newUrl = `${splitUrl[0]}/upload/h_${height},w_${width},${cropString}/${splitUrl[1]}`;

  return newUrl;
};

export const transformCloudinaryVideoUrlByHeight = (url: string, height: string): string => {
  const splitUrl = url.split("/upload/");

  if (splitUrl.length !== 2) {
    console.error("Invalid Cloudinary URL");
    return url;
  }

  const newUrl = `${splitUrl[0]}/upload/h_${height},c_fit/${splitUrl[1]}`;

  return newUrl;
};

export const generateProfileImageUrl = (url: string, width: string, height: string): string => {
  const crop = "thumb";
  const gravity = "face";
  const radius = "max";
  const format = "auto";

  const splitUrl = url.split("/upload/");

  if (splitUrl.length !== 2) {
    console.error("Invalid Cloudinary URL");
    return url;
  }

  const transformationString = `c_${crop},g_${gravity},h_${height},w_${width},r_${radius},f_${format}`;

  const newUrl = `${splitUrl[0]}/upload/${transformationString}/${splitUrl[1]}`;
  return newUrl;
};

export const showErrorToast = (message: string) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  });
};

export const generateCloudinaryVideoThumbnail = (url: string, width: string, height: string, timeInSeconds: number): string => {
  const splitUrl = url.split("/upload/");

  if (splitUrl.length !== 2) {
    console.error("Invalid Cloudinary URL");
    return url;
  }

  // Specify format as jpg to get a static image thumbnail
  const newUrl = `${splitUrl[0]}/upload/h_${height},w_${width},so_${timeInSeconds},c_fill,f_jpg/${splitUrl[1]}`;

  return newUrl;
};

export const splitName = (fullName: string) => {
  const words = fullName.split(" ");
  const lastName = words.pop();
  const firstName = words.join(" ");
  return { firstName, lastName };
};

export const filterNameInput = (value: string): string => value.replace(/[^a-zA-Z-]/g, "").slice(0, 35);

export const navigateWithState = (location: Location<ILocationState>, navigation: History) => {
  if (location.state?.lastPath) {
    navigation.push({
      pathname: location.state.lastPath,
      state: { from: location.pathname, fromValidFlow: true }
    });
  } else {
    location.state?.from
      ? navigation.push({
          pathname: location.state?.from,
          state: { from: location.pathname, fromValidFlow: true }
        })
      : navigation.goBack();
  }
};

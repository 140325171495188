import { useMemo } from "react";

import { useLazyQuery, useMutation } from "@apollo/client";

import { AUTH_CHECK, FETCH_USER, FETCH_USERS_CAMPAIGNS, FETCH_USERS_CAUSES, UPDATE_USER_PROFILE } from "../../../queries";
import { Campaign, Cause, User } from "../../../types";

const useUser = () => {
  const [fetchUser, { data: userData, loading, refetch: userRefetch }] = useLazyQuery<{ user: User }>(FETCH_USER, {
    fetchPolicy: "network-only"
  });
  const [updateProfile, { loading: isUpdatingProfile }] = useMutation(UPDATE_USER_PROFILE, {
    refetchQueries: [FETCH_USER]
  });

  const [fetchUserCampaign, { loading: campaignsLoading, data: campaignsData }] = useLazyQuery<{
    campaignsSearch: Campaign[];
  }>(FETCH_USERS_CAMPAIGNS, {
    fetchPolicy: "network-only"
  });

  const [fetchAuthCheck, { data: authData, loading: authLoading }] = useLazyQuery<{
    auth: { success: boolean };
  }>(AUTH_CHECK, {
    fetchPolicy: "network-only"
  });

  const [fetchUserCauses, { loading: userCauseLoading, data: userCauseData }] = useLazyQuery<{
    causes: Cause[];
  }>(FETCH_USERS_CAUSES, {
    fetchPolicy: "network-only"
  });
  const userCause = useMemo(() => {
    if (!userCauseData?.causes) return [];
    const causes = [...(userCauseData?.causes || [])] as Cause[];
    return causes;
  }, [!!userCauseData]);

  return {
    updateProfile,
    isUpdatingProfile,
    fetchUser,
    userData,
    loading,
    userRefetch,
    fetchUserCampaign,
    campaignsLoading,
    campaignsData,
    fetchAuthCheck,
    authData,
    authLoading,
    fetchUserCauses,
    userCauseLoading,
    userCause
  };
};

export default useUser;

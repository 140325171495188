import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoShareSocialOutline } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import HeadToHeadInfoCard from "../../components/headToHeadInfoCard/headToHeadInfoCard";
import HeadToHeadResult from "../../components/headToHeadResult/headToHeadResult";
import { HeadToHeadProps } from "../../components/headToHead/headToHead.types";
import HeadToHeadPlayCard from "../../components/headToHeadPlayCard/headToHeadPlayCard";

const HeadToHead: React.FC = () => {
  const location = useLocation();

  const { data } = location.state as { data: HeadToHeadProps };

  const { player1Stashlink, player2Stashlink, endTime, goal } = data.headToHeadData || {};

  return (
    <div className="min-h-screen bg-soft-black  text-white flex flex-col items-center">
      <div className="bg-gradient-to-t from-modal-bottom-bg to-modal-top-bg">
        <div className="flex w-screen justify-center">
          <div className="w-11/12 pt-2 gap-8 text-white rounded-lg">
            <div className="w-full h-12 flex items-center justify-between">
              <IoIosArrowBack className="text-grey text-2xl h-8 w-8" />
              <h3 className="font-primary font-lg text-center text-base-white">Head to Head</h3>
              <div className="relative w-8 h-8 bg-black/10 rounded-full">
                <IoShareSocialOutline className="absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 text-xl" />
              </div>
            </div>
            {data.battleStatus === "currentHeadToHeadBattle" && (
              <h1 className="font-primary text-2xl text-base-white text-center mb-4">${goal}Goal</h1>
            )}
          </div>
        </div>

        {data.battleStatus === "recentlyFinishedHeadToHeadBattle" ? (
          <HeadToHeadResult player1Stashlink={player1Stashlink} player2Stashlink={player2Stashlink} />
        ) : (
          <HeadToHeadPlayCard player1Stashlink={player1Stashlink} player2Stashlink={player2Stashlink} endTime={endTime} />
        )}
      </div>
      <HeadToHeadInfoCard />
    </div>
  );
};

export default HeadToHead;

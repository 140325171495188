import React from "react";
import { ImpactBadgeProps } from "./types";
import FlashIcon from "../../svgImages/flashIcon";
import { getStyles } from "../../../utils/common-function";

const ImpactBadge: React.FC<ImpactBadgeProps> = ({ impact, isImpactIconRequired = false, setIsUserProfileModalOpen, impactScoreLevel }) => {
  const openProfileModal = () => {
    setIsUserProfileModalOpen && setIsUserProfileModalOpen(true);
  };

  const styles = getStyles(impactScoreLevel);

  return (
    <div
      className={`inline-flex items-center rounded-full text-white relative badges mx-2 mr-0 mb-0 bg-black/[0.25] backdrop-blur-10.5 ${isImpactIconRequired ? `with-check absolute bottom-0 left-2/4 -translate-x-2/4 w-fit flex items-center text-base-white text-sm rounded-2xl border-b-3 border-r-3 border-black/40` : "without-check"}`}
      // style={{ background: styles.levelColor }}
      onClick={() => openProfileModal()}>
      <div className="bg-gradient-to-r from-black/0 to-black/0 space-x-2 rounded-xl px-2 py-px pt-c-3 ps-7 min-w-c-72">
        {isImpactIconRequired && (
          <span className="absolute left-0 -top-0.5 text-3xl mr-1 text-base-white">
            <FlashIcon color={styles.levelColor} className="h-8 w-auto relative left-[6px] -top-0.5" />
          </span>
        )}
        <span className="text-sm font-medium leading-tight-14.40 text-neutral-50 font-secondary !ml-0">{impact}</span>
      </div>
    </div>
  );
};

export default ImpactBadge;

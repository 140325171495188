import { socialMediaIconSize } from "../../common/constants";
const TwitterIcon = ({ color = "#FAFAFA", width = "28", height = "29", className = "" }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 28 29"  style={{ height: socialMediaIconSize.height, width: socialMediaIconSize.width }} fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3.5" y="4.30811" width="21" height="21" rx="5.83333" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M8.98005 20.339C9.26334 20.6223 9.72264 20.6223 10.0059 20.339L13.8454 16.4995L12.8782 15.415L8.98005 19.3132C8.69676 19.5964 8.69676 20.0557 8.98005 20.339Z"
      fill={color}
    />
    <path
      d="M19.6692 10.213C19.9525 9.92967 19.9525 9.47037 19.6692 9.18708C19.3859 8.90379 18.9266 8.90379 18.6433 9.18708L14.8038 13.0266L15.8297 14.0525L19.6692 10.213Z"
      fill={color}
    />
    <path
      d="M16.6092 19.8147C16.8063 20.0611 17.096 20.2173 17.4192 20.2173C17.7424 20.2173 18.0321 20.0611 18.2292 19.8147C18.5958 19.3565 18.5958 18.6559 18.2292 18.1976L11.5008 9.78707C11.3037 9.54071 11.0139 9.38452 10.6908 9.38452C10.3676 9.38452 10.0779 9.54071 9.88077 9.78707C9.51415 10.2454 9.51415 10.9459 9.88077 11.4042L16.6092 19.8147Z"
      stroke={color}
      strokeWidth="1.16065"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TwitterIcon;

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAuth } from "../../store/contextAPI/authContext";
import { getLocalStorage, isEmailValid, setLocalStorage } from "../../utils/common-function";
import { useMutation } from "@apollo/client";
import { CREATE_WAITLIST } from "../../queries";
import mixpanel from "../../services/mixpanelService";
import { WaitlistModalProps } from "./types";

const useWaitlistModal = (props: WaitlistModalProps) => {
  const { isOpen } = props;
  const { user, setEmailContext } = useAuth();
  const [email, setEmail] = useState({ value: user.email, error: "" });
  const [isWaitListJoined, setWaitlistJoined] = useState(false);
  const [createWaitlist, { data, error }] = useMutation(CREATE_WAITLIST);
  const isUserJoinedWaitlist: boolean = getLocalStorage("isUserJoinedWaitlist");
  const emailInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen && emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, [isOpen]);

  const onCloseModal = useCallback(() => {
    setEmail({ value: user.email || "", error: "" });
  }, [user.email]);

  const onChangeEmail = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const processedValue = value.replace(/\s+/g, "").toLowerCase();
      setEmail({ value: processedValue, error: "" });
    },
    [isEmailValid]
  );

  useEffect(() => {
    if (isOpen) {
      mixpanel.track("join-waitlist-open");
    }
  }, [isOpen, mixpanel]);

  const isJoinWaitlistDisable = useMemo((): boolean => email.value.length === 0, [email.value]);

  const onClickJoinWaitlistHandler = useCallback(async () => {
    if (isEmailValid(email.value)) {
      setEmailContext(email.value);
      await createWaitlist({
        variables: {
          email: email.value,
          accessAllowed: true
        }
      });
    } else {
      const emailError = email.value.length === 0 ? "" : isEmailValid(email.value) ? "" : "Please enter a valid email";
      setEmail({ value: email.value, error: emailError });
    }
  }, [email.value, setEmailContext, createWaitlist]);

  useEffect(() => {
    if (data && data.createWaitlist.success) {
      setWaitlistJoined(true);
      mixpanel.track("join-waitlist-submit");
      setLocalStorage("isUserJoinedWaitlist", true);
    } else if (error) {
      setEmail({ value: email.value, error: "Error occur in joining the waitlist." });
    }
  }, [data, error]);

  return {
    user,
    email,
    onCloseModal,
    isWaitListJoined,
    onChangeEmail,
    isJoinWaitlistDisable,
    onClickJoinWaitlistHandler,
    isUserJoinedWaitlist,
    emailInputRef
  };
};

export default useWaitlistModal;

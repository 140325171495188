import React from "react";
import Button from "../common/button";
import Coin from "../../assets/images/StashCoin.png";
import Cross from "../../assets/images/cross.svg";
import InputField from "../common/input/input";
import MarqueeComponent from "../common/marquee/marqueeComponent";
import { marqueeTextIteration } from "../../utils/constants";
import { FaHeart } from "react-icons/fa";
import useWaitlistModal from "./useWaitListModal";
import { WaitlistModalProps } from "./types";
import { generateCloudinaryImageUrl } from "../../utils/common-function";

const WaitlistModal: React.FC<WaitlistModalProps> = (props: WaitlistModalProps) => {
  const { isOpen, onClose, stashUser } = props;
  const {
    email,
    onChangeEmail,
    onCloseModal,
    isWaitListJoined,
    onClickJoinWaitlistHandler,
    isUserJoinedWaitlist,
    emailInputRef
  } = useWaitlistModal(props);
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-modal-bg/80 flex items-center justify-center z-20 backdrop-blur-5">
      <div className="text-base-white rounded-20 w-80 relative border-4 border-primary-l1 border-t-0 border-l-0  p-5 pt-6 pb-4 bg-grey-bg">
        <div className="bg-grey-bg/30">
          <button
            className="absolute text-white top-5 right-5"
            onClick={() => {
              onCloseModal();
              onClose();
            }}>
            <img src={Cross} alt={Cross} className="w-6 h-6 mb-4" />
          </button>

          {!isUserJoinedWaitlist && !isWaitListJoined ? (
            <div>
              <div className="w-full flex justify-center item-center mb-2">
                {stashUser?.profilePhoto ? (
                  <div className="w-[72px] h-[72px] rounded-full bg-primary-l1 overflow-hidden">
                    <img
                      src={generateCloudinaryImageUrl(stashUser.profilePhoto, "120", "120")}
                      alt="StashRun"
                      className="rounded-full w-[64px] translate-y-[7px] translate-x-[4px]"
                    />
                  </div>
                ) : (
                <img
                  src={Coin}
                  alt="StashRun"
                  className="w-c-72"
                />
                )}
              </div>
              <p className="text-center text-sm text-base-white font-secondary leading-tight-18.2 font-normal  mb-3">
                {stashUser?.fullName ? (
                  <>
                    Sign up for early access to {stashUser?.fullName?.split(" ")?.[0]}’s <br />
                    next play to fund campaign
                  </>
                ) : (
                  <>
                    Get early access to the next <br />
                    play to fund campaign
                  </>
                )}
              </p>
              <div className="w-full hello">
                <InputField
                  label="Email"
                  onChange={onChangeEmail}
                  name="Email"
                  inputRef={emailInputRef}
                  placeholder="example@email.com"
                  value={email.value}
                  error={email.error}
                  showClearIcon={email.value.length !== 0}
                />
                {email.error ? <p className="text-xs text-error -mt-10 mb-2">{email.error}</p> : <></>}
                <div className="h-14 select-none">
                  <Button title="Join the waitlist" isDisabled={false} onClick={onClickJoinWaitlistHandler} />
                </div>
                <div className="-mx-4 mt-4 mb-2">
                  <MarqueeComponent text="Be the first to know" iterationsPerScreen={marqueeTextIteration} separatorIcon={FaHeart} />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="w-full flex justify-center item-center mb-2">
                {stashUser?.profilePhoto ? (
                  <div className="w-[98px] h-[98px] rounded-full bg-primary-l1 overflow-hidden">
                    <img
                      src={generateCloudinaryImageUrl(stashUser.profilePhoto, "120", "120")}
                      alt="StashRun"
                      className="rounded-full w-[86px] translate-y-[11px] translate-x-[6px]"
                    />
                  </div>
                ) : (
                  <img src={Coin} alt="StashRun" className="h-c-120 w-c-120" />
                )}
              </div>
              <div className="text-base text-base-white font-bold letterSpacing-0.064px w-full text-center mb-2">You’re on the list!</div>
              <p className="text-center text-sm text-base-white/80 font-secondary leading-tight-18.2 font-normal mb-2">
                {stashUser?.fullName ? (
                  <>
                    We’ll reach out when {stashUser?.fullName?.split(" ")?.[0]}’s
                    next play to fund campaign goes live.
                  </>
                ) : (
                  <>
                    We’ll reach out when the next play <br />
                    to fund campaign goes live
                  </>
                )}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WaitlistModal;

import { useRef } from "react";
import { emojiBlasts } from "emoji-blast";

const useEmojiBlast = (duration = 1500, interval = 200) => {
  const cancelEmojiBlastRef = useRef<(() => void) | null>(null);

  const startEmojiBlast = () => {
    const { cancel } = emojiBlasts({
      interval,
      process(element) {
        element.className = "emoji-blast";
      }
    });

    cancelEmojiBlastRef.current = cancel; // Store cancel function in ref

    // Set a timeout to stop the emoji blast after the provided duration
    const emojiBlastTimeoutId = setTimeout(() => {
      cancel();
    }, duration);

    return () => {
      cancel();
      clearTimeout(emojiBlastTimeoutId);
    };
  };

  const stopEmojiBlast = () => {
    if (cancelEmojiBlastRef.current) {
      cancelEmojiBlastRef.current(); // Call cancel function to stop animation

      const activeBlasts = document.querySelectorAll(".emoji-blast"); // Select active blasts
      activeBlasts.forEach((blast) => {
        blast.remove(); // Remove the ongoing blasts immediately
      });
    }
  };

  return { startEmojiBlast, stopEmojiBlast };
};

export default useEmojiBlast;

import React from "react";
import { ShareButtonProps } from "./shareButton.types";
import ChatIcon from "./../../../assets/images/sms.svg";
import LinkIcon from "./../../../assets/images/link.svg";
import QrIcon from "./../../../assets/images/qr-code.svg";

const ShareButton: React.FC<ShareButtonProps> = (props: ShareButtonProps) => (
  <button
    className={`flex flex-col items-center text-primary-l1 ${props.disabled ? "cursor-not-allowed" : ""}`}
    onClick={!props.disabled ? props.buttonOnClick : undefined}>
    <div className="w-12 h-12 border-2 border-primary-l1 rounded-lg flex justify-center items-center">
      {props.buttonName === "SMS" ? (
        <img src={ChatIcon} alt={ChatIcon} />
      ) : props.buttonName === "Copy Link" ? (
        <img src={LinkIcon} alt={LinkIcon} />
      ) : (
        <img src={QrIcon} alt={QrIcon} />
      )}
    </div>
    <span
      className="text-center text-primary-l1/80 text-xs font-normal font-secondary leading-none mt-1">
      {props.buttonName}
    </span>
  </button>
);

export default ShareButton;

import Button from "../button";
import { IModal } from "./types";
import Close from "./../../../assets/images/cross.svg";

const ColinkGreyModal = (props: IModal) => {
  const { isOpen, onButtonClick, children, title, onClickClose, buttonTitle, isDisabled = false } = props;
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black-op80/80 bg-opacity-50 p-4 backdrop-blur-5">
      <div className="bg-modal-grey rounded-2xl shadow-lg w-96">
        <div className="relative flex justify-end items-center p-4 border-b border-base-white/50">
          <h2 className="w-fit text-center absolute left-2/4 -translate-x-2/4 text-base font-medium font-primary text-white">{title}</h2>
          <button onClick={onClickClose} className="text-white font-bold">
            <img src={Close} alt={Close} />
          </button>
        </div>
        <div className="px-4 py-5 pb-3">{children}</div>
        <div className="flex justify-end px-4 pb-4 pt-0 colink-modal-button">
          <Button onClick={onButtonClick} title={buttonTitle} isDisabled={isDisabled} />
        </div>
      </div>
    </div>
  );
};

export default ColinkGreyModal;

import React, { useEffect } from "react";
import SponsorModal from "../../components/common/sponsorModal/sponsorModal";
import mixpanel from "../../services/mixpanelService";
interface SponsorModalProps {
  sponsorImageUrl?: string;
  brandName: string;
  impactScoreLevel?: string | null;
  eligibleBrands?: {
    id: string;
    name: string;
    imageUrl: string;
  }[];
}

const MatchSponsor: React.FC<SponsorModalProps> = ({
  sponsorImageUrl,
  brandName,
  impactScoreLevel,
  eligibleBrands
}) => {
  useEffect(() => {
    mixpanel.track("brand-modal-viewed");
  }),
    [];

  return (
    <div className="justify-center items-center">
      <SponsorModal
        sponsorName={brandName}
        sponsorImageUrl={sponsorImageUrl || ""}
        impactScoreLevel={impactScoreLevel}
        eligibleBrands={eligibleBrands}
      />
    </div>
  );
};

export default MatchSponsor;

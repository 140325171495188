import { socialMediaIconSize } from "../../common/constants";
const YouTubeIcon = ({ color = "#FAFAFA", width = "28", height = "29", className = "" }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 28 29"  style={{ height: socialMediaIconSize.height, width: socialMediaIconSize.width }} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6699 18.3081L17.7319 14.8081L11.6699 11.3081V18.3081Z"
      stroke={color}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="3.5" y="4.30811" width="21" height="21" rx="5.83333" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default YouTubeIcon;

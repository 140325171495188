import React from "react";
import Marquee from "react-fast-marquee";
import { MarqueeComponentProps } from "./marqueeComponent.types";

const MarqueeComponent: React.FC<MarqueeComponentProps> = (props: MarqueeComponentProps) => (
  <Marquee className="bg-transparent py-1 -m-0.5">
    {[...Array(props.iterationsPerScreen)].map((_, index) => (
      <span className="text-sm flex items-center ml-3" key={index}>
        {props.text}
        {typeof props.separatorIcon === "string" ? (
          <span className="ml-3">{props.separatorIcon}</span>
        ) : (
          props.separatorIcon && <props.separatorIcon className="ml-3" />
        )}
      </span>
    ))}
  </Marquee>
);

export default MarqueeComponent;

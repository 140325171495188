import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LoadingVideo from "../../components/loadingVideo/loadingVideo";
import Button from "../../components/common/button";
import { IoIosArrowBack } from "react-icons/io";
import useCreateColink from "./hooks/useCreateColink";
import { Stashlink } from "../../types";
import {
  generateCloudinaryImageUrl,
  generateCloudinaryVideoUrl,
  getLocalStorage,
  navigateWithState,
  showErrorToast
} from "../../utils/common-function";
import useUser from "../../services/hooks/useUser";
import Loader from "../../components/common/loader/loader";
import mixpanel from "../../services/mixpanelService";
import { ToastContainer } from "react-toastify";
import VolumeFull from "../../assets/images/volume-full.svg";
import VolumeMute from "../../assets/images/Mute.svg";
import { ILocationState } from "../../components/profileMenu/types";

const ColinkConfirmation: React.FC = () => {
  const location = useLocation<ILocationState>();
  const history = useHistory();
  const userId = getLocalStorage("userId") as string;
  const { userData } = useUser(userId);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [muted, setMuted] = useState<boolean>(true);

  const { file, thankyouMessage, isCreateStashlink } = location.state as {
    file: Blob;
    thankyouMessage: string;
    isCreateStashlink: boolean;
  };
  const [videoLoading, setVideoLoading] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  let videoURL = "";
  if (file) {
    videoURL = URL.createObjectURL(file);
  }
  const stashlink = getLocalStorage("stashlink") as Stashlink;
  const parentStashlinkId = isCreateStashlink ? null : stashlink.parentStashlink?.id || stashlink.id;
  const campaignId = stashlink.campaign.id;
  const { createColink, loading, errorMessage } = useCreateColink(thankyouMessage, file, parentStashlinkId, campaignId);

  useEffect(() => {
    if (videoURL && !location.state.lastPath) {
      setVideoLoading(true);
      setTimeout(() => {
        setVideoLoading(false);
      }, 2000);
    }
  }, []);

  const handleMuteUnmute = () => {
    if (videoRef.current) {
      setMuted(!muted);
      mixpanel.track("sound-clicked", {
        location: "colink-confirmation-page",
        muted: !muted,
        campaignName: stashlink?.campaign?.title,
        stashlinkId: stashlink?.id
      });
      videoRef.current.muted = !muted;
    }
  };

  if (loading.loading)
    return (
      <div className="h-[calc(100vh-80px)] w-screen flex justify-center items-center  bg-soft-black">
        <Loader />
      </div>
    );

  if (errorMessage) {
    showErrorToast(errorMessage);
  }
  const handleNavigation = () => {
    navigateWithState(location, history);
  };

  return (
    <>
      {videoLoading ? (
        <LoadingVideo />
      ) : (
        <div className="bg-soft-black h-[calc(100vh-155px)] flex flex-col items-center">
          <div className="w-full h-[calc(100%-137px)] absolute">
            {videoURL ? (
              <div className="relative w-full h-full">
                <video ref={videoRef} className="w-full h-full object-cover" autoPlay loop playsInline muted>
                  <source src={generateCloudinaryVideoUrl(videoURL || "", "750", "930")} type="video/mp4" />
                  <div className="w-full h-10  bg-gradient-to-t from-soft-black to-soft-black/0"></div>
                  Your browser does not support the video tag.
                </video>
                <div
                  className="absolute z-10 top-2 right-2 rounded-full flex justify-center items-center p-2 bg-black/30 cursor-pointer"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleMuteUnmute();
                  }}>
                  <img src={muted ? VolumeMute : VolumeFull} alt="Volume" className="w-6 h-6" />
                </div>
              </div>
            ) : (
              <>
                <img
                  src={generateCloudinaryImageUrl(userData?.profilePhoto || "", "750", "930")}
                  alt={userData?.fullName}
                  className="w-full h-full object-cover"
                />
              </>
            )}
            <div className="absolute top-0 left-0 w-full p-4 py-8 bg-gradient-to-t from-soft-black/0 to-soft-black flex items-center justify-between">
              <IoIosArrowBack className="w-6 h-6 cursor-pointer absolute left-4 z-10 text-grey" onClick={handleNavigation} />
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                <h2 className="text-white text-xl">How&apos;s that look?</h2>
              </div>
            </div>
          </div>

          <div className="fixed w-full bottom-0 left-0">
            <p className="bg-gradient-to-t from-soft-black from-40% via-soft-black/70 via-70% to-base-white/0 to-90% text-white text-base leading-tight-19 text-center w-full p-4 px-5 pb-7 pt-16 [text-shadow:_2px_2px_0_rgb(0_0_0_/_60%)] inter-I">
              {thankyouMessage}
            </p>
            <div className="flex items-start px-6 p-2 pb-5 bg-soft-black">
              <input
                type="checkbox"
                id="tos-agreement"
                className="border border-grey"
                onChange={(e) => {
                  videoURL ? mixpanel.track("video-terms-checked") : mixpanel.track("message-terms-checked");
                  setIsChecked(e.target.checked);
                }}
              />
              <label htmlFor="tos-agreement" className="text-grey text-xs ps-2 font-medium font-secondary">
                The content of my {videoURL ? "video" : "message"} adheres to the{" "}
                <a href="/terms" target="_blank" className="text-grey font-bold underline">
                  TOS{" "}
                </a>
                and{" "}
                <a
                  className="text-grey font-bold underline"
                  href="https://www.stashrun.com/community-guidelines"
                  target="_blank"
                  rel="noreferrer">
                  Community Guidelines
                </a>
                .
              </label>
            </div>
            <div className="bg-black backdrop-blur-8 px-4 pt-3 pb-5 z-10">
              <Button title="Create stashlink + Share" isDisabled={videoLoading || !isChecked} onClick={createColink} />
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default ColinkConfirmation;

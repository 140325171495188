import Button from "../common/button";
import InputField from "../common/input/input";
import ErrorText from "../errorText";
import { buttonTitle, enterYourNameAttributes, labels, names, validationRules } from "../../modules/Accounts/Onboarding/constant";
import TextArea from "../common/text-area/text-area";
import { ProfileInputsProps } from "./types";
import ProfileInput from "./profileInput";
import "react-datepicker/dist/react-datepicker.css";

const ProfileInputs: React.FC<ProfileInputsProps> = ({
  firstName,
  lastName,
  onChangeFirstName,
  onChangeLastName,
  userBio,
  onChangeBio,
  instagramId,
  onChangeInstagramId,
  tiktokId,
  onChangeTiktokId,
  youtube,
  onChangeYoutube,
  onAddPhotoButtonHandler,
  fileInputRef,
  handlePhotoChange,
  profileImageUri,
  error,
  buttonDisabled,
  onClickSaveHandler,
  date,
  month,
  year,
  dateRef,
  monthRef,
  yearRef,
  dobErrorMessage,
  handleDateChangeWithFocus,
  handleMonthChangeWithFocus,
  handleYearChangeWithFocus,
  addZeroToDateMonth
}) => (
  <div className="w-full pt-3 pb-20">
    <div className="bg-primary-l1 rounded-t-2xl mt-12">
      <div className="bg-black/70 rounded-t-2xl">
        <div className="bg-gradient-to-t from-black/30 to-black/0 rounded-t-2xl p-4 edit-profile">
        <div>
          <div className="flex flex-col items-center mb-8">
            <div className="w-c-170 h-182 bg-primary-l1 rounded-full flex items-center justify-center -mt-16">
              <img src={profileImageUri} alt="Profile" className="rounded-full w-c-156 h-c-156 object-cover relative top-2" />
            </div>

            <button className="text-grey text-sm font-normal font-secondary mt-2" onClick={onAddPhotoButtonHandler}>
              Edit Photo
              <input ref={fileInputRef} type="file" accept="image/*" style={{ display: "none" }} onChange={handlePhotoChange} />
            </button>
          </div>

          <ProfileInput
            value={firstName}
            onChange={onChangeFirstName}
            label={enterYourNameAttributes.label.firstName}
            name={enterYourNameAttributes.name.firstName}
            placeholder={enterYourNameAttributes.placeHolder.firstName}
            showClearIcon={firstName.length !== 0}
            error={!firstName.length}
            errorMessage="First name should be there."
          />

          <ProfileInput
            value={lastName}
            onChange={onChangeLastName}
            label={enterYourNameAttributes.label.lastName}
            name={enterYourNameAttributes.name.lastName}
            placeholder={enterYourNameAttributes.placeHolder.lastName}
            showClearIcon={lastName.length !== 0}
            error={!lastName.length}
            errorMessage="Last name should be there."
          />

          <TextArea
            label={labels.bio}
            name={names.bio}
            value={userBio}
            onChange={onChangeBio}
            placeholder="You can add a short bio to tell people more about yourself"
            rows={3}
            maxLengthLabelOnTop={true}
            inputClassName="!bg-grey/5 !text-neutral-50 !placeholder:grey/50"
            currentLength={userBio.length}
            maxLength={140}
          />

          <div className="date-picker-input input mt-2 w-full">
            <label className="block text-sm text-grey font-normal">
              Birthday (Your birthday won&apos;t be shown publicly)
            </label>
            <div className="flex space-x-2">
              <InputField
                onChange={handleMonthChangeWithFocus}
                inputClassName="!w-66 !h-11 !py-2 !px-4 text-center !bg-grey/5 !text-neutral-50 !placeholder:grey/50"
                name={enterYourNameAttributes.name.firstName}
                placeholder="MM"
                value={month}
                inputMode="numeric"
                inputRef={monthRef}
                error={!month.length || (dobErrorMessage?.length || 0) > 0}
                handleClick={addZeroToDateMonth}
                handleBlur={addZeroToDateMonth}
              />
              <InputField
                onChange={handleDateChangeWithFocus}
                inputClassName="!w-66 !h-11 !py-2 !px-4 text-center !bg-grey/5 !text-neutral-50 !placeholder:grey/50"
                name={enterYourNameAttributes.name.lastName}
                placeholder="DD"
                value={date}
                inputMode="numeric"
                inputRef={dateRef}
                error={!date.length || (dobErrorMessage?.length || 0) > 0}
                handleClick={addZeroToDateMonth}
                handleBlur={addZeroToDateMonth}
              />
              <InputField
                onChange={handleYearChangeWithFocus}
                inputClassName="!w-76 !h-11 !py-2 !px-4 text-center !bg-grey/5 !text-neutral-50 !placeholder:grey/50"
                name={enterYourNameAttributes.name.lastName}
                placeholder="YYYY"
                value={year}
                inputMode="numeric"
                error={year.length!=4 || (dobErrorMessage?.length || 0) > 0}
                inputRef={yearRef}
                handleClick={addZeroToDateMonth}
              />
            </div>
            {dobErrorMessage ? (
              <div className="-mt-2 text-sm mb-3">
              <p className="text-xs font-bold text-error mx-0.5 font-secondary text-left">{dobErrorMessage}</p>
              <ul style={{ listStyleType: "disc" }} className="leading-[16.8px]">
                  <li className="mx-6 text-xs text-error">{validationRules.dob.dateFormat}</li>
                  <li className="mx-6 text-xs text-error">{validationRules.dob.ageLimit}</li>
                </ul>
              </div>
            ) : (
              <span></span>
            )}
          </div>

          {["Instagram", "Tiktok", "Youtube"].map((platform, index) => (
            <InputField
              key={index}
              value={{ Instagram: instagramId, Tiktok: tiktokId, Youtube: youtube }[platform] as string}
              onChange={
                {
                  Instagram: onChangeInstagramId,
                  Tiktok: onChangeTiktokId,
                  Youtube: onChangeYoutube
                }[platform] as (e: React.ChangeEvent<HTMLInputElement>) => void
              }
              label={`${platform} (optional)`}
              name={`${platform.toLowerCase()}Id`}
              placeholder="user name"
              inputClassName="!bg-grey/5 !text-neutral-50 !placeholder:grey/50"
              showClearIcon={
                {
                  Instagram: instagramId.length !== 0,
                  Tiktok: tiktokId.length !== 0,
                  Youtube: youtube.length !== 0
                }[platform]
              }
            />
          ))}

          {error && <ErrorText text={error} />}
          </div>
          <div className="fixed bottom-0 left-0 w-full p-4 bg-black z-10 select-none">
            <Button title={buttonTitle.save} isDisabled={buttonDisabled} onClick={onClickSaveHandler} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ProfileInputs;

import { useQuery, ApolloError } from "@apollo/client";
import { FETCH_USER_STASHLINKS } from "../../queries";
import { Stashlink } from "../../types";

interface userStashlinksResponse {
  user: any;
}

interface useUserStashlinksHook {
  userStashlinksLoading: boolean;
  userStashlinksError?: ApolloError;
  activeUserStashlinks: Stashlink[];
  inActiveUserStashlinks: Stashlink[];
}

const useUserStashlinks = (userId: string): useUserStashlinksHook => {
  const {
    loading: userStashlinksLoading,
    error: userStashlinksError,
    data
  } = useQuery<userStashlinksResponse>(FETCH_USER_STASHLINKS, {
    variables: {
      userId
    },
    fetchPolicy: "network-only"
  });

  const userStashlinksData = data?.user?.stashlinks || [];

  const currentTime = new Date();
  
  const inActiveUserStashlinks = userStashlinksData?.filter(
    (stashlink: Stashlink) =>
      stashlink?.campaign?.currentAmount >= stashlink?.campaign?.goalAmount ||
      (stashlink?.campaign?.endTime && new Date(stashlink?.campaign?.endTime) < currentTime) ||
      !stashlink?.campaign?.canSupport
  );

  const activeUserStashlinks = userStashlinksData.filter((stashlink: Stashlink) => !inActiveUserStashlinks?.includes(stashlink));

  return { userStashlinksLoading, userStashlinksError, inActiveUserStashlinks, activeUserStashlinks};
};

export default useUserStashlinks;

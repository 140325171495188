import images from "../../../assets/images/images";
import { preloadImages } from "../../../utils/common-function";
import { addCloudinaryTransformation } from "../../../utils/format";
import scroll from "../../../utils/scroll";
import { SponsorModalProps } from "./sponsor.types";
import React, { useEffect, useState } from "react";

const SponsorModal: React.FC<SponsorModalProps> = ({ sponsorName, sponsorImageUrl }) => {
  const { brandLogoAnimation } = images;
  const brandAnimation = addCloudinaryTransformation(brandLogoAnimation, "c_scale,w_100,dpr_2");
  const brandLogo = addCloudinaryTransformation(sponsorImageUrl, "c_scale,w_100,dpr_2");
  const [isMatched, setIsMatched] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    preloadImages([brandAnimation, brandLogo]);
    scroll.lock();
    const timer = setTimeout(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setIsMatched(true);
        setIsTransitioning(false);
      }, 1000);
    }, 1500);

    return () => {
      clearTimeout(timer);
      scroll.enable();
    };
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/60 bg-opacity-80 z-50 backdrop-blur">
      <div className="h-c-296 max-h-c-296 gap-8 sponsor-modal border-solid border-r-4 border-b-4 text-white p-11 rounded-3xl flex flex-col items-center justify-center w-80 mx-auto bg-grey-bg border-primary-l1">
        {!isMatched && (
          <div className={`text-center transition-opacity duration-700 ${isTransitioning ? "opacity-0" : "opacity-100"}`}>
            <h2 className="tracking-wide">
              Matching you <br /> with a sponsor...
            </h2>
          </div>
        )}

        <div
          className={`flex items-center justify-center rounded-full overflow-hidden transition-all duration-700 w-[144px] h-[144px] ${
            isMatched ? "border-base-white border-4" : ""
          } ${isTransitioning ? "opacity-0 scale-90" : "opacity-100 scale-100"}`}>
          <div className={`w-full h-full rounded-full overflow-hidden ${ isMatched ? "border-2 border-soft-black" : " "}`}>
{/*             TODO: For Gif pass brandAnimation instead brandLogo and remove className circle */}
            <img src={brandLogo} alt="" className={`w-full h-full ${!isMatched ? "circle" : ""}`} />
          </div>
        </div>

        {isMatched && (
          <div
            className={`text-center transition-all duration-700 ${
              isTransitioning ? "opacity-0 translate-y-4" : "opacity-100 translate-y-0"
            }`}>
            <h2 className="tracking-wider">{sponsorName}</h2>
            <span className="font-normal font-Inter">will pay for this donation</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SponsorModal;

import * as React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import Store from "./store";
import { App } from "./containers";
import Routes from "./routes";
import { RootRouteComponentWithSubRoutes } from "./routes/routeWrapper";

import "./assets/css/index.css";
import { AuthProvider } from "./store/contextAPI/authContext";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <Router>
        <AuthProvider>
          <App>
            <RootRouteComponentWithSubRoutes routes={Routes} />
          </App>
        </AuthProvider>
      </Router>
    </Provider>
  </React.StrictMode>
);

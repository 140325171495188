import { useState, useEffect } from "react";
import { Campaign } from "../../../types";

const useCampaignFunded = (campaign?: Campaign) => {
  const [isCampaignFunded, setIsCampaignFunded] = useState<boolean>(false);

  useEffect(() => {
    if (campaign?.currentAmount && campaign?.goalAmount) {
      if (campaign.currentAmount >= campaign.goalAmount) {
        setIsCampaignFunded(true);
      } else {
        setIsCampaignFunded(false);
      }
    }
  }, [campaign]);

  return isCampaignFunded;
};

export default useCampaignFunded;

import { Redirect } from "react-router-dom";
import { envVariables } from "../../common/constants";

const stashlinkId = envVariables.staticStashlinkId; // Get the hard-coded ID

const RedirectComponent = () => (
<Redirect to={`/stashlink/${stashlinkId}`} />
);

export default RedirectComponent;

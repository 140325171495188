import React, { useRef, useEffect, useState } from "react";
import { NumberImageProps } from "./numberImage.types";

const NumberImage: React.FC<NumberImageProps> = (props: NumberImageProps) => {
  const [imageSrc, setImageSrc] = useState<string>("");
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        // Set color background
        ctx.fillStyle = props.backGroundColor || "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Set text properties
        ctx.font = "40px Arial";
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        // Draw the number on the canvas
        ctx.fillText(props.number.toString(), canvas.width / 2, canvas.height / 2);

        // Convert the canvas to a data URL and set it as the image source
        setImageSrc(canvas.toDataURL());
      }
    }
  }, [props.number]);

  return (
    <div>
      <canvas ref={canvasRef} width={100} height={100} style={{ display: "none" }} />
      {imageSrc && <img src={imageSrc} alt={`Number ${props.number}`} className={props.imageClassName} />}
    </div>
  );
};

export default NumberImage;

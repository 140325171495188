import React from "react";
import { useHistory } from "react-router";
import useScrollToSection from "../../services/hooks/useScrollToSection";
import { tableOfContents } from "../../utils/termsAndPrivacyData";

const TermsOfService: React.FC = () => {
  const history = useHistory();
  useScrollToSection(history);

  return (
    <div className="bg-soft-black text-white mx-auto p-4 ">
      <div className="bg-soft-black rounded-lg flex items-center justify-between">
        <div className="relative flex justify-center items-center w-full">
          <h1 className="text-base font-bold font-primary text-left leading-tight-19.2 tracking-0.004em">TERMS OF SERVICE</h1>
        </div>
      </div>
      <div className="bg-soft-black text-base-white mx-auto p-4 ">
        <p className="font-sm font-secondary font-semibold leading-tight-18.2 text-left tracking-0.004em mb-4">
          Last Revised on January 05, 2023
        </p>
        <p className="mb-4 font-inter text-14px font-normal leading-tight-18.2 text-left">
          These Terms of Service (these “Terms”) for the website,{" "}
          <a href="https://www.stashrun.com" className=" underline">
            www.stashrun.com
          </a>{" "}
          and the related mobile application(s) (the “App”) operated on behalf of Stashrun Inc (“Company”, “we”, “us” or “our”). The Website
          and any content, tools, features and functionality offered on or through our Website and the App are collectively referred to as
          the “Services”.
        </p>
        <p className="mb-4 font-inter text-14px font-normal leading-tight-18.2 text-left">
          These Terms govern your access to and use of the Services. Please read these Terms carefully, as they include important
          information about your legal rights. By accessing and/or using the Services, you are agreeing to these Terms. If you do not
          understand or agree to these Terms, please do not use the Services.
        </p>
        <p className="mb-4 font-inter text-14px font-normal leading-tight-18.2 text-left">
          For purposes of these Terms, “you” and “your” means you as the user of the Services. If you use the Services on behalf of a
          company or other entity then “you” includes you and that entity, and you represent and warrant that (a) you are an authorized
          representative of the entity with the authority to bind the entity to these Terms, and (b) you agree to these Terms on the
          entity’s behalf.
        </p>
        <p className="mb-4 font-inter text-14px font-normal leading-tight-18.2 text-left">
          Section{" "}
          <a href="#section-9" className=" underline">
            9
          </a>{" "}
          contains an arbitration clause and class action waiver. By agreeing to these Terms, you agree (a) to resolve all disputes with us
          related to the Services through binding individual arbitration, which means that you waive any right to have those disputes
          decided by a judge or jury, and (b) to waive your right to participate in class actions, class arbitrations, or representative
          actions in connection with your use of the Services. You have the right to opt-out of arbitration as explained in Section{" "}
          <a href="#section-9" className=" underline">
            9
          </a>
          .
        </p>
        <p className="font-semibold font-secondary text-xl mb-4">TABLE OF CONTENTS</p>
        {tableOfContents.map((section, index) => (
          <div key={index} id={section.id}>
            {section.title && <p className="font-semibold font-secondary font-base mb-4">{section.title}</p>}
            {section.description && (
              <p className="mb-4 font-inter text-14px font-normal leading-tight-18.2 text-left">{section.description}</p>
            )}
            {section.subSections &&
              section.subSections.map((item, subIndex) => (
                <div key={subIndex}>
                  {item.title && <p className="font-semibold font-secondary font-base">{item.title}</p>}
                  {item.content && <p className="mb-4 font-inter text-14px font-normal leading-tight-18.2 text-left">{item.content}</p>}
                  <div className="mb-4">
                    {Array.isArray(item.points) &&
                      item.points.map((point, pointIndex) => (
                        <div key={pointIndex}>{typeof point === "string" ? <p>{point}</p> : <>{point}</>}</div>
                      ))}
                  </div>
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TermsOfService;

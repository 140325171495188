import { useQuery, ApolloError } from "@apollo/client";
import { User } from "../../types";
import { FETCH_FEATURED_USERS } from "../../queries/fetch-featured-users";

interface UseFeaturedUsersResponse {
  featuredUsers: User[];
}

interface UseFeaturedUsersHook {
  featuredUsersLoading: boolean;
  featuredUsersError?: ApolloError;
  featuredUsersData?: User[];
}

const useFeaturedUsers = (): UseFeaturedUsersHook => {
  const {
    loading: featuredUsersLoading,
    error: featuredUsersError,
    data
  } = useQuery<UseFeaturedUsersResponse>(FETCH_FEATURED_USERS, {
    variables: {},
    fetchPolicy: "cache-first"
  });
  const featuredUsersData = data?.featuredUsers;
  return { featuredUsersLoading, featuredUsersError, featuredUsersData };
};

export default useFeaturedUsers;

import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "../../../routes/routes.constant";
import { Stashlink } from "../../../types";

const useCheckStashLink = (stashLinkLoading: boolean, stashLink?: Stashlink) => {
  const navigate = useHistory();

  useEffect(() => {
    if (!stashLinkLoading && stashLink === null) {
      navigate.push(routes.pageNotFound);
    }
  }, [stashLink, stashLinkLoading, navigate]);

};

export default useCheckStashLink;

import React from "react";
import { InputFieldProps } from "./input.types";
import { inputType } from "../../../common/constants";
import useInput from "./useInput";
import InputCross from "./../../../assets/images/inputCross.svg";
import EyeOff from "./../../../assets/images/eyeOff.svg";
import EyeOn from "./../../../assets/images/eyeOn.svg";

const InputField: React.FC<InputFieldProps> = ({
  name,
  maxLength,
  label,
  placeholder,
  value,
  onChange,
  type = inputType.text,
  error,
  inputRef,
  containerClassName,
  inputClassName,
  showClearIcon,
  currentLength,
  handleClick,
  inputMode
}) => {
  const { minDate, maxDate, isFocused, isPasswordVisible, togglePasswordVisibility, wrapperRef, handleFocus, handleBlur } = useInput();

  return (
    <div ref={wrapperRef} className={`mb-4 ${containerClassName} relative`}>
      {label && <label className="block text-sm font-normal text-grey inter-I">{label}</label>}
      <div className="relative mt-1">
        <input
          inputMode={inputMode}
          maxLength={maxLength}
          name={name}
          type={type === inputType.password ? (!isPasswordVisible ? inputType.password : inputType.text) : type}
          placeholder={placeholder}
          value={value}
          ref={inputRef}
          onChange={onChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onClick={handleClick}
          min={type === inputType.date ? minDate : ""}
          max={type === inputType.date ? maxDate : ""}
          className={`text-sm text-base-white mt-1 block w-full px-3 py-3 pe-12 border ${value ? "bg-pure-black/[.25]" : "bg-grey/5"} h-11 ${error ? "border-red-500 border-2" : "border-grey-op70/70 focus:border-grey"} rounded-md shadow-sm focus:border-2 focus:outline-none focus:bg-pure-black/[.25] sm:text-sm ${inputClassName}`}
        />
        {isFocused && showClearIcon && (
          <img
            src={InputCross}
            alt={InputCross}
            className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer text-grey"
            onClick={() => {
              onChange({ target: { name, value: "" } } as React.ChangeEvent<HTMLInputElement>);
            }}
          />
        )}
        {type === inputType.password &&
          value.length > 0 &&
          (isPasswordVisible ? (
            <img
              src={EyeOn}
              alt={EyeOn}
              className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer text-grey"
              onClick={togglePasswordVisibility}
            />
          ) : (
            <img
              src={EyeOff}
              alt={EyeOff}
              className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer text-grey"
              onClick={togglePasswordVisibility}
            />
          ))}
      </div>
      {maxLength && (
        <div className={`text-xs ${error ? " text-error" : "text-grey"} mt-1 flex justify-end`}>
          {currentLength}/{maxLength}
        </div>
      )}
    </div>
  );
};

export default InputField;

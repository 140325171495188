import { gql } from "@apollo/client";

export const CREATE_STASHLINK = gql`
  mutation CreateStashlink($input: CreateStashlinkInput!) {
    createStashlink(input: $input) {
      success
      error
      stashlink {
        id
        url
        user {
          id
          fullName
        }
        campaign {
          id
          title
        }
        thankYouMessage
        thankYouMessageVideo
        parentStashlink {
          id
          user {
            id
            fullName
          }
        }
      }
    }
  }
`;

import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/common/button";
import Dollar from "./../../assets/images/Dollar.svg";
import { useHistory, useLocation } from "react-router";
import { routes } from "../../routes/routes.constant";
import { oneDollar } from "../../common/constants";
import { generateCloudinaryImageUrl, getLocalStorage } from "../../utils/common-function";
import { SampleEligibleBrandsData } from "../../types";
// import useAuthStatus from "./hooks/useAuthStatus";
// import { useImpactScoreModal } from "../../services/hooks/useImpactScoreModal";
// import ImpactScoreModal from "../../components/impactScoreModal/impactScoreModal";
import mixpanel from "../../services/mixpanelService";
import { emojiBlasts } from "emoji-blast";
import "../../assets/css/index.css";
import { formatTimeInSeconds } from "../../utils/format";

const GameComplete: React.FC = () => {
  const navigation = useHistory();
  const coinDropId = getLocalStorage("coinDropId");
  const brandData = getLocalStorage("brandsData") as SampleEligibleBrandsData;
  // const { currentUser } = useAuthStatus();
  // const { isImpactScoreModalOpen, setIsImpactScoreModalOpen, impactScoreLevel } = useImpactScoreModal(currentUser?.user);
  const location = useLocation();
  const cancelEmojiBlastRef = useRef<(() => void) | null>(null);
  const [time, setTime] = useState(0);

  useEffect(() => {
    setTime(getLocalStorage("gameCompleteTime"));
    const { cancel } = emojiBlasts({
      interval: 700,
      process(element) {
        element.className = "emoji-blast";
      }
    });

    cancelEmojiBlastRef.current = cancel; // Store cancel function in ref

    const state = location.state as { fromValidFlow: boolean };
    if (!state?.fromValidFlow) {
      const stashLink = getLocalStorage("stashLink");
      navigation.push(stashLink ? stashLink : "/");
    }
    setTimeout(() => {
      cancel(); // Stop the emoji blast
    }, 300);
  }, [location.state, navigation]);

  const stopEmojiBlast = () => {
    if (cancelEmojiBlastRef.current) {
      cancelEmojiBlastRef.current(); // Call cancel function to stop animation

      const activeBlasts = document.querySelectorAll(".emoji-blast"); // Select active blasts
      activeBlasts.forEach((blast) => {
        blast.remove(); // Remove the ongoing blasts immediately
      });
    }
  };

  return (
    <div className="relative flex flex-col gap-6 w-screen justify-start pt-7 items-center h-[calc(100vh-133px)] bg-soft-black text-white">
      <div className="relative z-30 gap-6 flex flex-col items-center">
        <h2 className="text-22px text-neutral-50 font-primary font-bold leading-normal tracking-tight">Donation done!</h2>
        <div className="scale-in gap-4 overflow-hidden border-r-4 border-b-4 border-primary-l1 text-white px-2 py-4 pb-5 rounded-3xl flex flex-col items-center w-80 mx-auto bg-grey-bg to-modal-top-bg">
          <div className="flex flex-col items-center justify-center">
            <p className="font-secondary font-normal text-neutral-50 text-base leading-tight">
              in just {Math.ceil(+formatTimeInSeconds(time))} seconds, you stashed
            </p>
            <h1 className="text-primary-l1 text-28px font-extrabold font-primary my-1">${oneDollar}</h1>

            <div className="font-secondary text-base font-normal text-neutral-50 leading-tight">
              for <span className="font-bold">{brandData.sampleEligibleBrands?.campaign.charity?.name}</span>
            </div>
          </div>
          <div className="w-full h-full rounded-lg overflow-hidden relative">
            <div className="p-2 absolute -left-2 -top-2 w-[72px] h-[72px] rounded-full bg-grey-bg flex justify-center items-center">
              <img
                src={generateCloudinaryImageUrl(brandData.sampleEligibleBrands?.campaign.charity?.imageUrl || "", "104", "104")}
                alt="charity logo"
                className="h-c-34 w-c-34 object-cover rounded-full"
              />
            </div>

            <img
              src={generateCloudinaryImageUrl(brandData.sampleEligibleBrands?.campaign?.imageUrl || "", "596", "424")}
              alt="campaign image"
              className="w-full aspect-video object-cover rounded-xl"
            />
          </div>
          <div className="flex flex-row gap-1">
            <img src={Dollar} alt={Dollar} />

            <a className="no-underline" href="https://www.stashrun.com/disclosures" target="_blank" rel="noreferrer">
              <p className="font-secondary text-grey-op70">#{coinDropId || 0}</p>
            </a>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 w-full flex p-4 z-30 items-center justify-center bg-pure-black">
        <Button
          title="Continue"
          isDisabled={false}
          onClick={() => {
            mixpanel.track("donation-complete-continue-clicked", {
              campaignName: brandData.sampleEligibleBrands?.campaign?.title,
              charityName: brandData.sampleEligibleBrands?.campaign?.charity?.name,
            });
            stopEmojiBlast();
            navigation.replace({ ...location, state: {} });
            navigation.replace(routes.campaignThankYou, { fromValidFlow: true });
          }}
        />
      </div>
      {/* {isImpactScoreModalOpen && (
        <ImpactScoreModal
          user={currentUser?.user}
          impactScoreLevel={impactScoreLevel}
          setIsImpactScoreModalOpen={setIsImpactScoreModalOpen}
        />
      )} */}
    </div>
  );
};

export default GameComplete;

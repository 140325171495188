import { _apiRequest } from "./apiAction";
import { _hideSpinner, _showSpinner } from "./uiAction";
import { _fetchUsers, _updateUsers } from "./usersAction";
import { _fetchAttendees, _fetchAttendeesbyOffsite, _storeUpdateAttendees } from "./attendeesAction";

// API ACTION
export const API_REQUEST = "[app] API Request";
export const apiRequest = _apiRequest;

// USERS ACTION
export const FETCH_USERS = "[users] FETCH";
export const FETCH_USERS_SUCCESS = "[users] Fetch success";
export const FETCH_USERS_ERROR = "[users] Fetch Error";
export const UPDATE_USERS = "[users] Update";
export const fetchUsers = _fetchUsers;
export const updateUsers = _updateUsers;

// SPINNER ACTION
export const SHOW_SPINNER = "[ui] show spinner";
export const HIDE_SPINNER = "[ui] hide spinner";
export const hideSpinner = _hideSpinner;
export const showSpinner = _showSpinner;

// FIRESTORE
export const FETCH_ATTENDEES = "[attendees] FETCH ALL";
export const FETCH_ATTENDEES_OFFSITE = "[attendees offsite] FETCH ALL BY OFFSITE";
export const STORE_UPDATE_ATTENDEES = "[attendees] UPDATE STORE WITH ATTENDEES";
export const fetchAttendees = _fetchAttendees;
export const fetchAttendeesbyOffsite = _fetchAttendeesbyOffsite;
export const storeUpdateAttendees = _storeUpdateAttendees;

import * as React from "react";
import { connect } from "react-redux";

import MainHeader from "./layout/header/header";
import MainContent from "./layout/main";

const AppWrapper: React.FC<IProps> = (props: IProps) => (
  <div>
    {/* HEADER LAYOUT */}
    <MainHeader />

    {/* MAIN LAYOUT */}
    <MainContent>{props.children}</MainContent>

    {/* FOOTER LAYOUT */}
    {/* <MainFooter data="Copyright intimetec 2023" /> */}
  </div>
);

const mapStateToProps = (state: any) => ({
  pending: state.ui.pending
});

export interface IProps {
  pending?: boolean;
  children: React.ReactNode;
}

export default connect(mapStateToProps, {})(AppWrapper);

import { useMutation } from "@apollo/client";
import { FOLLOW_ENTITY } from "../../queries";

const useFollowEntity = (entityId: string, refetchFollowingEntity: () => void) => {
  const [followEntity] = useMutation(FOLLOW_ENTITY);

  const followCelebrity = async (isFollow: boolean) => {
    try {
      const { data } = await followEntity({
        variables: {
          entityType: "User",
          entityId,
          isFollow
        }
      });

      if (data.followEntity.success) {
        refetchFollowingEntity();
      } else {
        console.error("Error following celebrity:", data.followEntity.error);
      }
    } catch (error) {
      console.error("Mutation error:", error);
    }
  };

  return {
    followCelebrity
  };
};

export default useFollowEntity;

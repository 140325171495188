import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ILocationState, IProfileMenu } from "./types";
import Cross from "./../../assets/images/cross.svg";
import LogoutModal from "../logoutModal/logoutModal";
import useProfileMenu from "./useProfileMenu";
import { routes } from "../../routes/routes.constant";

const ProfileMenu: React.FC<IProfileMenu> = (props: IProfileMenu) => {
  const history = useHistory();
  const location = useLocation<ILocationState>();
  const { isUserLoggedIn, isModalOpen, handleLogoutClick, onConfirm, setIsModalOpen, isEmailProvider } = useProfileMenu(props);

  if (!isUserLoggedIn) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex flex-col items-start p-6">
      <button className="text-white text-2xl mb-6 relative -top-3 -left-2" onClick={() => props.onClose()}>
        <img src={Cross} alt={Cross} className="w-6 h-6 mb-4" />
      </button>
      <nav className="flex flex-col space-y-6 mt-3">
        {isUserLoggedIn && (
          <a
            onClick={() => {
              props.onClose();
              history.push({
                pathname: routes.editProfile,
                state: {
                  ...location.state,
                  from: location.pathname,
                  lastPath: location.state?.lastPath || location.state?.from
                }
              });
            }}
            className="font-primary font-bold text-2xl text-primary-l1">
            Edit profile
          </a>
        )}
        {isUserLoggedIn && isEmailProvider && (
          <a
            onClick={() => {
              props.onClose();
              history.push({
                pathname: routes.changePassword,
                state: {
                  ...location.state,
                  from: location.pathname,
                  lastPath: location.state?.lastPath || location.state?.from
                }
              });
            }}
            className="font-primary font-bold text-2xl text-primary-l1">
            Update password
          </a>
        )}
        {isUserLoggedIn && (
          <p onClick={handleLogoutClick} className="font-primary font-bold text-2xl text-primary-l1">
            Logout
          </p>
        )}
        <LogoutModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} onConfirm={onConfirm} />
      </nav>
    </div>
  );
};

export default ProfileMenu;

import React from "react";
import useButton from "./useButton";

interface IButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  title: string;
  isDisabled: boolean;
}

const Button: React.FC<IButtonProps> = (props: IButtonProps) => {
  const { title, onClick, isDisabled } = props;
  const { className, containerClass } = useButton(props);

  return (
    <div className={containerClass}>
      <button disabled={isDisabled} onClick={onClick} className={className}>
        {title}
      </button>
    </div>
  );
};

export default Button;
